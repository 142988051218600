<div>
    <div *ngIf="busy1">
          <div class="placeholder_card">
            <div class="card-text">There is no sprint associate with script</div>
          </div>
      </div>
      <div *ngIf="busy" class="test-method-container">
        <div class="data-table">
          <div class="row">
            <div class="header cell">Sprint Id</div>
            <div class="header cell">Test Method</div>
            <div class="header cell">Channel Name</div>
            <div class="header cell">Sprint Name</div>
          </div>
          <div>
          <div class="area">
          <div class="row list" *ngFor="let item of allSprintListsFilterd" >
              <div class="cell" style="word-break: break-all">{{ item.id}}</div>
              <div class="cell">{{item.type}}</div>
              <div class="cell">{{ item.channel}}</div>
              <div class="cell" style="word-break: break-all;">{{ item.name}}</div>
        </div>
        </div>
        </div>
        </div>
        <div class="btn-set" style="text-align: center;">
          
          <app-button class="btn" [size]="'sm'" (click)="closeDialog()"
              >
              Cancel</app-button>
      </div>
       
      </div>
    </div>
    