<div class="common-paginator-wrapper" >
    <div class="item jump" (click)="gotoBack()" [class.disabled]="activePage === 0">Previous</div>
    <!-- <div class="item jump" *ngIf="pages[0] > 0" (click)="gotoFirst()">First</div> -->
    <div class="item" *ngFor="let page of pages;let i = index" [class.active]="page === activePage"
        (click)="goto(page)">
        {{ page+1 }}
    </div>
    <!-- <div class="item jump" *ngIf="pages[pages.length-1] < totalPages-1" (click)="gotoLast()">Last</div> -->
    <div class="item jump" (click)="gotoNext()" [class.disabled]="activePage+1 === totalPages">Next</div>
    <app-form-select>
        <select (change)="changePagination($event.target.value)">
            <option val=""></option>
            <option val="all">all</option>
            <option val="25">25</option>
            <option val="50">50</option>
            <option val="100">100</option>
        </select>
    </app-form-select>
</div>
 
