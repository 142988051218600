import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageContainerComponent implements OnInit {
  @Input() title;

  constructor() { }

  ngOnInit(): void {
  }

}
