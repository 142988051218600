import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserQuery } from '../user/user.query';
import {GenesystokenService} from '../genesystoken.service';
import { map } from 'rxjs/operators';
import { ScriptFolder } from '../model/scriptFolder';
import { param } from 'jquery';
@Injectable({ providedIn: 'root' })
export class requestsService {
    baseApi = environment.baseApi;
    baseApi1 = environment.baseApi1;
    httpheaders = {
        headers: new HttpHeaders(
            {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        )
    };
    mulipart = {
        headers: new HttpHeaders(
            {
                'Content-Type': 'multipart/form-data',
                'accept': "Accept=*/*" 
            }
        )
    };

    fileHeaders = {
        headers: new HttpHeaders({
        })
    }
    

    imgHeaders = {
        headers: new HttpHeaders({
            'accept': 'image/*'
        })
    }
    textHeader ={
        headers: new HttpHeaders(
            {
                responseType:'text'
            }
        )
        }
    audiohttpOptions = {
        headers: new HttpHeaders(
            {
                'Content-Type': 'application/octet-stream',
                'accept': 'application/octet-stream'
            }
        )
    };

    constructor(
        private userQuery: UserQuery,
        private http: HttpClient,private genesys:GenesystokenService) {
    }
    user() {

    }
    getSuggetionText(id){
        return this.query('/mlInfo/getTextSuggestion?scriptId='+id)
    }
    playAndPause(id : number , pause: boolean){
        var region = this.userQuery.getRegion();
        var baseApi2=region=='eu-central-1'?this.baseApi1:this.baseApi
        return this.http.get(baseApi2+'/sprint/resumePauseSprint?sprintId='+id +"&pause="+pause);

    }
    playAndPauseFolder(id : number , pause: boolean){
        var region = this.userQuery.getRegion();
        var baseApi2=region=='eu-central-1'?this.baseApi1:this.baseApi
        return this.http.get(baseApi2+'/foldersprint/resumePauseSprint?folderId='+id +"&pause="+pause);
    }
    getSkilss(parms?){
       
        return this.post('/sprint/getFive9SkillList',parms);

    }
    playAndPauseScriptFolder(id : number , pause: boolean){
        var region = this.userQuery.getRegion();
        var baseApi2=region=='eu-central-1'?this.baseApi1:this.baseApi
        return this.http.get(baseApi2+'/folderscript/resumePauseScript?folderId='+id +"&pause="+pause);

    }
    query(url, httpheaders = this.httpheaders ) {
        var region = this.userQuery.getRegion();
        return this.http.get<any>(
            `${region=='eu-central-1'?this.baseApi1:this.baseApi}${url}`,
            httpheaders, 
        );
    }
    newquery(url, headers_object = this.httpheaders, responseType = 'blob') {
        const region = this.userQuery.getRegion();
    
        // Create the options object
        const options = {
            headers: headers_object,
            responseType: responseType,
            observe: 'body' // or 'events', depending on your needs
        };
    
        return this.http.get<any>(
            `${region === 'eu-central-1' ? this.baseApi1 : this.baseApi}${url}`,
            options as any // Use type assertion to satisfy TypeScript
        );
    }
    
    
    post(url, params = null, httpheaders = this.httpheaders) {
        var region = this.userQuery.getRegion();
        return this.http.post<any>(
            `${region=='eu-central-1'?this.baseApi1:this.baseApi}${url}`,
            params,
            httpheaders
        );
    }
    newpost(url, params = null) {
        var region = this.userQuery.getRegion();
        return this.http.post<any>(
            `${region=='eu-central-1'?this.baseApi1:this.baseApi}${url}`,
            params,
        );
    }

    put(url, params = null) {
        var region = this.userQuery.getRegion();
        return this.http.put<any>(
            `${region=='eu-central-1'?this.baseApi1:this.baseApi}${url}`,
            params,
            this.fileHeaders
        );
    }

    delete(url, headers = null) {
        var region = this.userQuery.getRegion();
        const httpheaders = this.httpheaders;
        return this.http.delete<any>(
            `${region=='eu-central-1'?this.baseApi1:this.baseApi}${url}`,
            httpheaders
        );
    }

    userPlanDetails() {
        return this.query('/getCognitoUserGroup');
    }

    getChannels() {
        return this.query('/channel/list');
    }
    getlistAllChannelsForServiceMonitoring() {
        return this.query('/channel/listAllChannelsForServiceMonitoring');
    }
    getDataDrivenList(){
        return this.query('/userProfile/getDataDrivenFile')
    }
    deleteDataDrivenFile(fileId){
        return this.delete('/userProfile/deleteDataDrivenFile?fileId='+fileId)
    }
    uploadeFileDataDrien(params){
        var region = this.userQuery.getRegion();
        if(region=='eu-central-1'){
            return this.http.post('https://euc1api.quicktest.ai/userProfile/uploadDataDrivenFile', params);
        }else{
        return this.http.post('https://api.quicktest.ai/userProfile/uploadDataDrivenFile', params);
        }

    }
    getServiceMonitoringParms(channelId){
        return this.query('/serviceMonitor/getServiceMonitoringParametersByChannel?channelId='+channelId);

    }
    getServiceMonitoringById(id){
        return this.query('/serviceMonitor/getServiceMonitor?serviceMonitorId='+id);

    }
    addNewServiceMonitoring(data){
        return this.post('/serviceMonitor/addUpdateData', data);

    }
    getAllChannel() {
        return this.query('/channel/listAllChannels');
    }

    allServiceList() {
        return this.query('/service/list');
    }

    allServiceMonitoringList() {
        return this.query('/serviceMonitor/listServiceMonitor');
    }
    deleteServiceMonitoring(id){
        return this.delete('/serviceMonitor/deleteServiceMonitor?serviceMonitorId=' + id);

    }
    getService(id) {
        return this.query('/service/get?serviceId=' + id);
    }

    createService(params) {
        return this.post('/service/addUpdate', params);
    }
    updateService(params) {
        return this.post('/service/addUpdate', params);
    }

    removeService(id) {
        return this.delete('/service/delete?serviceId=' + id);
    }

    allScriptsList() {
        return this.query('/script/list');
    }

    allScriptsByChannelId(id) {
        return this.query('/script/listScriptByChannel?channelId=' +id);
    }

    allServiceBychannelId(id) {
        return this.query('/service/listServiceByChannel?channelId='+id);
    }
    allServiceBychannelIdsubId(id,subChannel) {
        return this.query(`/service/listServiceByChannelAndSubchannel?channelId=${id}&subChannelId=${subChannel}`);
    }
    getScript(id) {
        return this.query('/script/get?scriptId=' + id);
    }
    createScript(params) {
        return this.post('/script/addUpdate', params);
    }

    updateScript(params) {
        return this.post('/script/addUpdate', params);
    }
    //below api added on 26/07/2024
updateSprintConfig(params){
    return this.post('/foldersprint/updateSprintConfig', params);
}
    removeScript(id) {
        return this.delete('/script/delete?scriptId=' + id);
    }

    pricingPlans() {
        return this.query('/membership/list');
    }

    allSprints() {
        return this.query('/sprint/listSprint');
    }

    allSprintsByType(type) {
        return this.query('/sprint/listSprintByTestType?testType=' + type);
    }
    deleteFutureDate(sprintId){
        return this.query('/sprint/deleteFutureSchedules?sprintId='+sprintId)
    }

    createSprint(params) {
        return this.post('/sprint/addUpdateSprint', params);
    }

    sprintById(id) {
        return this.query('/sprint/getSprint?sprintId=' + id);
    }
    // http://demo.onecg.cc:8901/api/v2/onecgtest/sprint/getSprintName/%7Bs[rintid%7D
    getsprintById(id,params) {
        return this.post('/sprint/getAutoPilotHistByDate?sprintId=' + id,params);
    }
    runSpring(id,hybridClientId?:number) {
        let queryParams = `?sprintId=${id}`;

        if (hybridClientId) {
            queryParams += `&hybridClientId=${hybridClientId}`;
        }

        return this.query(`/sprint/runSprint${queryParams}`);
    }
    runVoiceSpring(id) {
        return this.query('/sprintV2/runSprintForWin?sprintId=' + id);
    }

    stopSprint(sprint, client) {
        return this.query(`/sprint/terminateSprint?sprintId=${sprint}&clientId=${client}`,{ headers: new HttpHeaders({ timeout: `${120000}` }) });
    }
    stopSingleSprint(sprint, client){
        return this.query(`/sprint/terminateSprint?sprintId=${sprint}&clientId=${client}`);

    }
    stoploadSprint(sprint, ) {
        return this.query(`/sprint/terminateLoadTest?loadstartsprintId=${sprint}`);
    }

    removeSpring(id) {
        return this.delete('/sprint/deleteSprint?sprintId=' + id);
    }

    sprintTestHistory(id) {
        return this.query('/autopilotMessage/listMessageData?customerId=' + id);
    }

    sprintStatus(id) {
        return this.query('/sprint/getSprintStatus?clientId=' + id);
    }

    agentProgressSummary(id, clientId) {
        return this.query('/sprint/refreshSprintClientResponse?sprintId=' + id + '&clientId=' + clientId);
    }
    agentVoiceProgressSummary(id, clientId) {
        return this.query('/sprintV2/refreshSprintClientResponseForWin?sprintId=' + id + '&clientId=' + clientId);
    }

    sprintTestHistoryList(id,params) {
        return this.post('/sprint/getAutoPilotHist?sprintId=' + id,params);
    }
    generateExcelFile(data) {
        const userToken = this.userQuery.getToken();
        var region = this.userQuery.getRegion();
        if(region=='eu-central-1'){
            var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
            return this.http.post('https://euc1api.quicktest.ai/autopilotFile/generateExcelFile',data, {headers:headers_object,responseType:'blob'});
        }else{
            var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
        return this.http.post('https://api.quicktest.ai/autopilotFile/generateExcelFile',data, {headers:headers_object,responseType:'blob'});
        }
    }

    sprintAudioURL(id) {
        const userToken = this.userQuery.getToken();
        // console.log(userToken)
        var region = this.userQuery.getRegion();
            if(region=='eu-central-1'){
                var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);

                return this.http.get('https://euc1api.quicktest.ai/autopilotMessage/getAudio?customerId='+id, {headers:headers_object,responseType: 'blob'});
            }else{
        var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);

        return this.http.get('https://api.quicktest.ai/autopilotMessage/getAudio?customerId='+id, {headers:headers_object,responseType: 'blob'});
            }
        //  return this.baseApi + '/autopilotMessage/getAudio?customerId=' + id;
    }
    getAudio(id){
        const userToken = this.userQuery.getToken();
        // // console.log(userToken)
        var region = this.userQuery.getRegion();
            if(region=='eu-central-1'){
                var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);

                return this.http.get('https://euc1api.quicktest.ai/autopilotMessage/getAudio?customerId='+id, {headers:headers_object,responseType: 'arraybuffer'});
            }else{
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);

         return this.http.get('https://api.quicktest.ai/autopilotMessage/getAudio?customerId='+id, {headers:headers_object,responseType: 'arraybuffer'});
            }
    }

    sprintAudioDownload(id) {
        return this.query('/autopilotMessage/getAudio?customerId=' + id, this.audiohttpOptions);
    }

    createStripeCheckoutSession(plan) {
        return this.post('/paymentcheckout/savePaymentCard?membershipId=' + plan);
    }

    savePaymentMethodForCustomer(sessiondId) {
        return this.post('/paymentcheckout/savePaymentMethodForCustomer?id=' + sessiondId, { id: sessiondId })
    }

    upgradePlan(planId) {
        return this.post('/paymentcheckout/upgardeMembership?membershipId=' + planId, { membershipId: planId });
    }

    getPaymentMethod() {
        return this.post('/paymentcheckout/getPaymentMethodForCustomer');
    }

    cancelMembership() {
        return this.query('/paymentcheckout/cancelMembership');
    }

    membershipHistory() {
        return this.query('/paymentcheckout/membershipHistory');
    }

    getTestTypeForUser() {
        return this.query('/sprint/getTestTypeForGroup');
    }

    getSuccessCriteria() {
        return this.query('/sprint/getSuccessCriteria');
    }

    getSuccessCriteriaReturnValue(id) {
        return this.query('/sprint/getCriteriaValues?successCriteriaId=' + id);
    }

    invoiceReport(params): Observable<any[]> {
        return this.post('/report/getInvoiceDetails', params);
    }

    highLevelReport(params): Observable<any[]> {
        return this.post('/report/getHighLevelDetails', params);
    }
    loadSprintReport(params): Observable<any> {
        return this.post('/sprint/getHighLevelLoadSprintReport', params);
    }
    loadReport(params): Observable<any> {
        return this.post('/sprint/getLoadSprintReport', params);
    }
    loadSprintDeatilsReport(loadSprintclientId) {
        return this.query('/sprint/getLoadSprintDetailReport?loadSprintclientId=' + loadSprintclientId);
    }
    sprintReport(params): Observable<any> {
        return this.post('/report/billing', params);
    }
    getSprintReport(params): Observable<any> {
        return this.post('/customDashboard/getSprintReport', params);
    }
    uploadProfileImg(params) {
        return this.post('/user/uploadImage', params, this.fileHeaders);
    }

    getUserdetails() {

        if(this.genesys.tokenType=='genesys'){
            var userToken = this.genesys.token;

            console.log(userToken)
             var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
            return this.http.get('https://api.quicktest.ai/getUserDetails',{headers:headers_object})
        }
        else{
            const userToken = this.userQuery.getToken();
            var region = this.userQuery.getRegion();
            console.log(region)
            if(region=='eu-central-1'){
                var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
                return this.http.get('https://euc1api.quicktest.ai/getUserDetails',{headers:headers_object})
            }else{
            var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
            return this.http.get('https://api.quicktest.ai/getUserDetails',{headers:headers_object})
            }
        }

       // return this.query('/getUserDetails');
    }
    updateTimeZoneProfil(timeZone){
     return this.post('/user/updateTimeZone?timeZone=' +timeZone);
    }
    getUserTimeZone(){
        var region = this.userQuery.getRegion();
        var baseApi2=region=='eu-central-1'?this.baseApi1:this.baseApi
       return this.http.get(baseApi2+'/user/getTimeZone',{responseType:'text'});

    }
    getProfileImg() {
        if(this.genesys.tokenType=='genesys'){
            var userToken = this.genesys.token;

            console.log(userToken)
             var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
            return this.http.get('https://api.quicktest.ai/user/getImage',{headers:headers_object,responseType:'blob'})
        }else{
            const userToken = this.userQuery.getToken();
            var region = this.userQuery.getRegion();
            if(region=='eu-central-1'){
                var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
            return this.http.get('https://euc1api.quicktest.ai/user/getImage',{headers:headers_object,responseType:'blob'})
            }else{
            var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
            return this.http.get('https://api.quicktest.ai/user/getImage',{headers:headers_object,responseType:'blob'})
            }
        }
       
       // return this.query('/user/getImage', {headers:headers_object});
    }

    contactus(params): Observable<any> {

        return this.post('/user/contactUs', params);
    }
    getPaymentDetails() {
        return this.query('/paymentcheckout/getCardDetails');
    }
    getupdatePayment() {
        return this.query('/paymentcheckout/updatePayment');
    }
    SaveupdatePayment(paymentId) {
        return this.post('/paymentcheckout/savePaymentMethodForCustomer?id='+paymentId,{id:paymentId});
    }
    LoadsprintStatus(id) {
        return this.query('/sprint/getLoadTestStatus?loadstartsprintId=' + id);
    }
    LoadSubsprintStatus(id,loadSubRunId) {
        return this.query('/sprint/getLoadTestStatusForSubRun?loadstartsprintId=' + id+ '&loadSubRunId=' + loadSubRunId);
    }
    LoadReportSubsprintStatus(id,loadSubRunId) {
        return this.query('/sprint/getLoadTestReportSubRunStatus?loadstartsprintId=' + id+ '&loadSubRunId=' + loadSubRunId);
    }
    saveLoadTest(id,loadSubRunId) {
        return this.query('/sprint/saveLoadTestStatus?loadstartsprintId=' + id+ '&loadSubRunId=' + loadSubRunId);
    }
    LoadrunSpring(id) {
        return this.query('/sprint/runLoadSprint?sprintId=' + id);
    }

   LoadsprintTestHistoryList(id) {
        return this.query('/sprint/getLoadSprintData?sprintId=' + id);
    }
    LoadsprintTestHistoryListbyDate(id,params) {
        return this.post('/sprint/getLoadSprintDataByDate?sprintId=' + id,params);
    }

    loadStatussprintById(id,loadSubRunId) {
        return this.query('/sprint/getLoadTestStatus?loadstartsprintId=' + id+ '&loadSubRunId=' + loadSubRunId);
    }
    getLoadTestStatusForReport(id) {
        return this.query('/sprint/getLoadTestStatusForReport?loadstartsprintId=' + id);
    }

    loadStatusssprintDetails(id) {
        return this.query('/sprint/getLoadSprintDetailReport?loadSprintclientId='+id);
    }
    loadStatusssprintMessage(id) {
        return this.query('/autopilotMessage/listMessageData?customerId='+id);
    }

    getDashboardSprintData() {
        return this.query('/dashboardSprint/dashboardScheduleSprint');
    }
    ServiceMonitoringRuns(id) {
        return this.query('/serviceMonitor/latestServiceMonitoringRuns?folderId='+id);
    }

    getCountryPhoneNo(coundtryCode) {
        return this.query('/icd/listNumbers?countryCode='+coundtryCode);
    }

    addPhoneNumber(params) {
        return this.post('/fromDetails/addFromNumber', params);
    }

    getListNumber() {
        return this.query('/fromDetails/listNumbers');
    }
    listNumbersInCountryDialing() {
        return this.query('/fromDetails/listNumbersInCountryDialing');
    }
    getActiveNumber() {
        return this.query('/whatsappSettings/getActiveWhatsAppDetails');
    }

    deletePhonenumber(phoneNumber) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }),
            body: {
                number:phoneNumber
            },
          };
          var region = this.userQuery.getRegion();
        var url='/fromDetails/releaseNumber';
        return this.http.delete<any>(
            `${region=='eu-central-1'?this.baseApi1:this.baseApi}${url}`,options
        );
        // return this.ht
        // return this.delete('/fromDetails/releaseNumber?number=' + phoneNumber);
    }
    getChatSubChannels(channelId) {
        return this.query('/channel/subchannels?channelId=' +channelId);
    }

    cloudchatRefreshSprintExecution(id, clientId) {
        return this.query('/sprint/refreshSprintExecution?sprintId=' + id + '&clientId=' + clientId);
    }
    cloudchatRefreshSprintVoiceExecution(id, clientId) {
        return this.query('/sprintV2/refreshSprintExecutionForWin?sprintId=' + id + '&clientId=' + clientId);
    }
    addWhatsappNumber(params): Observable<any> {
        const userToken = this.userQuery.getToken();
        // console.log(userToken)
        var region = this.userQuery.getRegion();
        if(region=='eu-central-1'){
            var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
        return this.http.post('https://euc1api.quicktest.ai/whatsappSettings/addWhatsappNumber', params,{headers:headers_object});
        }else{
        var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
        return this.http.post('https://api.quicktest.ai/whatsappSettings/addWhatsappNumber', params,{headers:headers_object});
        }
    }
    getWhatsAppDetails() {
        return this.query('/whatsappSettings/getWhatsAppDetails');
    }
    terminatewhatsapp(id) {
        return this.query('/whatsappSettings/terminate?id='+id);
    }
    
    terminateWhatsappAddNumber(id) {
        return this.query('/whatsappSettings/terminateWhatsappAddNumber?id='+id);
    }
    getQRCode(id) {
        const userToken = this.userQuery.getToken();
        // console.log(userToken)
        var region = this.userQuery.getRegion();
        if(region=='eu-central-1'){
            var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);

            return this.http.get('https://euc1api.quicktest.ai/whatsappSettings/getqrCode?id='+id, {headers:headers_object,responseType:'blob'});
        }else{
        var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);

        return this.http.get('https://api.quicktest.ai/whatsappSettings/getqrCode?id='+id, {headers:headers_object,responseType:'blob'});
        }
    }
    WhatsApptStatus(id) {
        return this.query('/whatsappSettings/getqrstatus?id=' + id);
    }
    voiceRefreshSprintExecution(id, clientId) {
        return this.query('/sprintV2/refreshSprintExecutionForWin?sprintId=' + id + '&clientId=' + clientId);
    }
    stopVoiceSprint(sprint, client) {

        return this.query(`/sprintV2/terminateSprintForWin?sprintId=${sprint}&clientId=${client}`,{ headers: new HttpHeaders({ timeout: `${120000}` }) })
    }
    LoadTestStatusGraph(sprint, client) {
        return this.query(`/sprint/getLoadTestStatusForGraph?loadstartsprintId=${sprint}&loadSubRunId=${client}`);
    }
    LoadTotalTestStatusGraph(sprint,) {
        return this.query(`/sprint/getTotalLoadTestStatusForGraph?loadstartsprintId=${sprint}`);
    }
    selectLanguage() {
        var region = this.userQuery.getRegion();
        if(region=='eu-central-1'){
            return this.http.get('https://euc1api.quicktest.ai/user/getLanguage',{responseType: 'text'})
        }else{
        return this.http.get('https://api.quicktest.ai/user/getLanguage',{responseType: 'text'})
        }
    }
    addUserLang(params) {
        return this.post('/user/updateLanguage?language='+ params);
    }
   getAvgConfidenceAndResult(id){
       return this.query(`/sprint/getAvgConfidenceAndResult?clientId=${id}`)
   }
   makebrowserCall(params){
    return this.post(`/browserCalling/makeCall`,params);
    }
    callTerminate(params) {
        return this.post('/browserCalling/terminateCall', params);
    }
    getHistoryBrowserCalling(parms){
        return this.post('/browserCalling/getBrowserCallingHist' , parms)
    }
    sendDTMF(params) {
        return this.post('/browserCalling/sendDTMF', params);
    }
    getCallMessages(params) {
        return this.post('/browserCalling/getCallMessages', params);
    }
    getIvrTree(params){
        return this.post("/ivrTree/getIvrTree",params)
    }
    terminateIvrCall(params){
        return this.post("/ivrTree/terminateCall",params)
    }
    getIvrMessage(params){
        return this.post("/ivrTree/getMessages",params)
    }
   UserListScriptTemp(){
       return this.query(`/userPreferences/listScriptPreferenceTemplates`)
   }
   UserListScriptTempByChannelId(channelId){
    return this.query(`/userPreferences/listScriptPreferenceTemplatesByChannel?channelId=${channelId}`)
}
   addUpdateScriptPreference(params){
    return this.post(`/userPreferences/addUpdateScriptPreference`,params)
    }
    deleteScriptPreference(sprint){
        return this.delete(`/userPreferences/deleteScriptPreference?id=${sprint}`)
    }
    UpdateScriptPreference(sprint){
        return this.query(`/userPreferences/getScriptPreference?id=${sprint}`)
    }
    UserListSprintTemp(){
        return this.query(`/userPreferences/listSprintPreferenceTemplates`)
    }
    UserListSprintTempByChannel(channelId , testType){
        return this.query(`/userPreferences/listSprintPreferenceTemplatesByGivenChannelIdsForLoad?channelIds=`+channelId+"&testType="+testType)
    }
    addUpdateSprintPreference(params){
        return this.post(`/userPreferences/addUpdateSprintPreference`,params)
    }
    deleteSprintPreference(sprint){
        return this.delete(`/userPreferences/deleteSprintPreference?id=${sprint}`)
    }
    UpdateSprintPreference(sprint){
        return this.query(`/userPreferences/getSprintPreference?id=${sprint}`)
    }
    getDefaultScriptData(sprint){
        return this.query(`/userPreferences/getDefaultScriptData?templateId=${sprint}`)
    } 
    getDefaultSprintData(sprint){
        return this.query(`/userPreferences/getDefaultSprintData?templateId=${sprint}`)
    }
    updateDefaultNumber(params){
        return this.post('/fromDetails/updateDefaultNumber', params);
    } 
    updatePersonalDefaultNumber(params){
        return this.post('/fromDetails/updateDefaultPersonalNumber', params);
    }   
    getSuccessCriteriaByChannel(sprint,subChannel){
        return this.query(`/sprint/getSuccessCriteriaByChannel?channelId=${sprint}&subChannelId=${subChannel}`);
    }
    getMultipleSuccessCriteriaDetails(sprint,subChannel){
        return this.query(`/sprint/getMultipleSuccessCriteriaDetails?channelId=${sprint}&subChannelId=${subChannel}`)
    }

    listUserChannel(){
        return this.query('/userChannels/listUserChannel')
    }
    addUpdateFilter(params){
        return this.post('/userChannels/addUpdateFilter', params);
    } 
    uploadBulkScript(params){
        // const userToken = this.userQuery.getToken();
        // console.log(userToken)
        // var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
        var region = this.userQuery.getRegion();
        if(region=='eu-central-1'){
            return this.http.post('https://euc1api.quicktest.ai/script/bulkUpload', params,);
        }else{
        return this.http.post('https://api.quicktest.ai/script/bulkUpload', params,);
        }
        // return this.post('/script/bulkUpload',params)
    }
    // bulkExcelfile():Observable<any> {
    //     return this.http.get('https://api.quicktest.ai/script/downloadTemplate',this.audiohttpOptions,{ResponseType: 'blob'})
    // }
    bulkExcelfile() {
        const userToken = this.userQuery.getToken();
        var region = this.userQuery.getRegion();
        if(region=='eu-central-1'){
            var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);

            return this.http.get('https://euc1api.quicktest.ai/script/downloadTemplate', {headers:headers_object,responseType:'blob'});
        }else{
        // console.log(userToken)
        var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);

        return this.http.get('https://api.quicktest.ai/script/downloadTemplate', {headers:headers_object,responseType:'blob'});
        }
    }
    rapidExcelFile(){
        return this.newquery('/defaultSetup/downloadTemplate')
    }
    generateToken() {
        return this.query('/user/generateApiKeyAndToken')
       }
    getGenesysRemotePhoneList(params){
        return this.post(`/sprint/getGenesysRemotePhoneList`,params)
    }
    getAllGoogleModel(){
        return this.query('/user/getAllGoogleModel')
    }
    getAllPromptTimeout(){
        return this.query('/user/getAllPromptTimeout')
    }
    updateGoogleModelAndTimeout(params){
        return this.post(`/user/updateGoogleModelAndTimeout`,params)
    }
    getGoogleModelAndTimeout(){
        return this.query('/user/getGoogleModelAndTimeout')
    }
    getAllEmailProfile(){
        return this.query(`/emailProfile/getAllEmailProfile`)
    }
    addEmailProfile(params){
        return this.post(`/emailProfile/addEmailProfile`,params)
    }
    getEmailProfile(sprint){
        return this.query(`/emailProfile/getEmailProfile?id=${sprint}`)
    }
    deleteEmailProfile(sprint){
        return this.delete(`/emailProfile/deleteEmailProfile?id=${sprint}`)
    }
    getPlatform(sprint){
        return this.query(`/sprint/getPlatform?sprintId=${sprint}`)
    }
    addScriptFolder(params){
        return this.post(`/script/addFolder`,params)
    }
    addSprintFolder(params){
        return this.post(`/foldersprint/addFolder`,params)
    }
    addServiceFolder(parms){
        return this.post(`/service/addFolder`,parms)

    }
    listServiceFolder(){
        return this.query('/service/listParentFolder')

    }
    listSprintFolder(){
        return this.query('/foldersprint/listFolder')

    }
    getServiceListFolder(id){
        return this.query(`/service/listServiceByFolder?folderId=${id}`)
    }
    getSprintListFolderById(id){
        return this.query(`/sprint/listSprintByFolder?folderId=${id}`)

    }
    getSprintFolderDetails(id){
        return this.query(`/sprint/listSprintByFolder?folderId=${id}`)

    }
     deletFolderService(folderId){
        return this.delete(`/service/deleteFolder?folderId=${folderId}`)

    }
    deletFolderSprint(folderId){
        return this.delete(`/foldersprint/deleteFolder?folderId=${folderId}`)

    }
    listScriptByFolder(){
        const userToken = this.userQuery.getToken();
            console.log(userToken)
            var region = this.userQuery.getRegion();
            var region = this.userQuery.getRegion();
            var baseApi2=region=='eu-central-1'?this.baseApi1:this.baseApi
            var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
        return this.http.get<any[]>(baseApi2+'/script/listParentFolder', {headers:headers_object}).pipe(map(data => data.map(data => new ScriptFolder().deserialize(data)))) ;;
    }
    listParentAndChildFolders(){
        return this.query(`/script/listParentAndChildFolders`)
    
    }
   getSprintOfFolderDetails(sprint){
    return this.query(`/foldersprint/getFolderDetails?folderId=${sprint}`)

   }
    getFolderDetails(sprint){
        return this.query(`/script/getFolderDetails?folderId=${sprint}`)
    }
    getSprintListFolder(sprint){
        return this.query(`/script/listScriptByFolder?folderId=${sprint}`)
    }
    getFolderExecutionStatus(params){
        return this.post(`/foldersprint/getFolderExecutionStatus`,params)
    }
    getExecutionReportSprint(params){
        return this.query(`/foldersprint/getFolderExecutionReport`,params)

    }
    getFolderScriptStatus(folderExecutionId){
        return this.query(`/folderscript/getFolderScriptStatus?folderExecutionId=${folderExecutionId}`)

    }
    updateFolderDetails(params){
        return this.post(`/folderscript/updateFolderDetails`,params)
    }
    updateSprintFolderDetails(params){
        return this.post(`/foldersprint/updateFolderDetails`,params)
    }
    startfolderScript(params){
        return this.post(`/folderscript/runFolderScript`,params)
    }
    runFolderSprint(params){
        return this.post(`/foldersprint/runFolderSprint`,params)
    }
    runSprintById(id){
        return this.query('/foldersprint/runSprint?sprintId='+id);
    }
    runSprint(scriptId,sprintid){
        return this.query(`/folderscript/runScript?scriptId=${scriptId}&sprintId=${sprintid}`)
    }
    getSprintBychannelId(channelId){
        return this.query(`/sprint/getAllSprintsByChannel?channelId=${channelId}`)
    }
    delScriptFolder(folderId){
        return this.delete(`/script/deleteFolder?folderId=${folderId}`)
    }
    UpdateScriptFolder(params){
        return this.post(`/script/updateFolderName`,params)
    }
    updateFolderScript(scriptId,folderId){
        return this.query(`/script/updateFolderId?scriptId=${scriptId}&folderId=${folderId}`)
    }
    getAllFacebookProfile(){
        return this.query(`/facebookProfile/getAllFacebookProfile`)
    }
    addFacebookProfile(params){
        return this.post(`/facebookProfile/addFacebookProfile`,params)
    }
    getFacebookProfile(sprint){
        return this.query(`/facebookProfile/getFacebookProfile?id=${sprint}`)
    }
    deleteFacebookProfile(sprint){
        return this.delete(`/facebookProfile/deleteFacebookProfile?id=${sprint}`)
    }
    getFolderExecutionReport(params){
        return this.post(`/folderscript/getFolderExecutionReport`,params)
    }
    getFolderExecutionSprintReport(params){
        return this.post(`/foldersprint/getFolderExecutionReport`,params)
    }
    getWhatsappNumberForNotification(){
        return this.query(`/sprint/getWhatsappNumberForNotification`)
    }
    getKeyMatchDataFromApi(params){
        return this.post(`/sprint/getKeyMatchDataFromApi`,params)
    }
    getGenesysQueueList(params){
        return this.post(`/sprint/getGenesysQueueList`,params)
    }
    listRecordandPlay(){
        return this.query(`/userPreferences/listRecordAndPlay`)
    }
    getGenesysSkillList(params){
        return this.post(`/sprint/getGenesysSkillList`,params)
    }
    getSprintFailedReasons(clientId){
        return this.query(`/sprint/getSprintSuccessAndFailedReasons?clientId=${clientId}`)
    }
    getSprintSuccessReasons(clientId){
        return this.query(`/sprint/getSprintSuccessAndFailedReasons?clientId=${clientId}`)
    }
    // GenesysCampaignList(params){
    //     return this.post(`/sprint/getGenesysCampaignList`,params)
    // }
    gettranslateLang(clientId,lang){
        return this.query(`/autopilotMessage/translatelistMessageData?customerId=${clientId}&language=${lang}`)
    }
    getConversationalApiRunDetails(clientId){
        return this.query(`/sprint/getConversationalApiRunDetails?clientId=${clientId}`)
    }
    sendMemberInvite(params){
        return this.post(`/userProfile/sendMemberInvite`,params)
    }
    isValidInviteCheck(params){
        return this.post(`/userProfile/isValidInvite`,params)
    }
    getUserRoleList(){
        return this.query(`/userProfile/getUserRoleList`)
    }
    getSubOrganizationList(){
        return this.query(`/userProfile/getSubOrganizationList`)

    }
    //added on 24/01/24
   getFolderSubOrg(folderId){
    return this.query(`/service/getFolderSuborg?folderId=`+folderId)
   } 
   updateFolderSubOrg(folderId,subOrgId){
    return this.query(`/service/updateFolderSubOrg?folderId=${folderId}&subOrgId=${subOrgId}`)
   }
   getScriptFolder(folderId){
    return this.query(`/script/getFolderDetails?folderId=`+folderId)
   } 
   getSprintFolder(folderId){
    return this.query(`/foldersprint/getFolderDetails?folderId=`+folderId)
   } 

    addSubOrganization(item){
        return this.post('/userProfile/createSubOrganization',item)
    }
    getSubOrganizationItem(id){
        return this.query(`/userProfile/getSubOrganizationById?subOrgId=`+id)

    }
    assignSubOrganizationToUser(data){
        return this.post('/userProfile/assignSubOrganizationtoUser',data)

    }
    enableUser(userId){
        return this.query(`/userProfile/enableUser?userId=`+ userId)

    }
    disableUser(userId){
        return this.query(`/userProfile/disableUser?userId=`+ userId)
    }
    getPendingInvitation(){
        return this.query(`/userProfile/getPendingInvitesByOrganization`)
    }
    getRoleListForUser(userId){
        return this.query(`/userProfile/getRoleListForUser?userId=${userId}`)
    }
    saveRoleListForUser(params){
        return this.post(`/userProfile/saveRoleListForUser`,params)
    }
    getSliderImg(clientId){
        return this.query(`/sprint/getImageList?clientId=${clientId}`)
    }
    showImage(clientId){
        return this.query(`/sprint/showImage?sprintId=${clientId}`)
    }
    getRoleListByUsername(){
        return this.query(`/userProfile/getRoleListByUsername`)
    }
    listChannel(){
        return this.query(`/foldersprint/listChannels`)
    }
    updateFolderSprint(sprintId,folderId){
        return this.query(`/sprint/updateFolderId?sprintId=${sprintId}&folderId=${folderId}`)
    }
    updateFolderService(serviceId,folderId){
        return this.query(`/service/updateFolderId?serviceId=${serviceId}&folderId=${folderId}`)
    }
    getFolderSprintStatus(sprintId){
        return this.query(`/foldersprint/getFolderSprintStatus?folderExecutionId=${sprintId}`)
    }
    serviceMonitor(){
        return this.query(`/serviceMonitor/listServiceMonitor`)
    }
    addUpdateData(params){
        return this.query(`/serviceMonitor/addUpdateData`,params)
    }
    getServiceMonitoringParametersByChannel(channelId){
        return this.query(`/serviceMonitor/getServiceMonitoringParametersByChannel?channelId=${channelId}`)
    }
    getFolderSprintAudioQuality(folderId){
        return this.query(`/foldersprint/getFolderSprintAudioQuality?folderId=${folderId}`)
    }
    getExecutionDate(clientId){
        return this.query(`/sprint/getExecutionDateNew?clientId=${clientId}`)
    }
    listGenesysAccountDetails(){
        return this.query(`/userProfile/listGenesysAccountDetails`)
    }
    addUpdateGenesysAccountDetails(params){
        return this.post(`/userProfile/addUpdateGenesysAccountDetails`,params)
    }
    getGenesysAccountDetails(id){
        return this.query(`/userProfile/getGenesysAccountDetails?id=${id}`)
    }
    //added on 30th may
    getElasticCSDetails(id){
        return this.query(`/sprint/getElasticCXQueueList?envId=${id}`)
    }
    deleteGenesysAccountDetails(id){
        return this.delete(`/userProfile/deleteGenesysAccountDetails?id=${id}`)
    }
    getAuditLogEntityList(){
        return this.query(`/auditLog/getAuditLogEntityList`)
    }
    getActionPerformed(){
        return this.query(`/auditLog/getActionPerformed`)
    }
    getAuditLogList(params){
        return this.post(`/auditLog/getAuditLogList`,params)
    }
    uploadAudio(params){
        var region = this.userQuery.getRegion();
        if(region=='eu-central-1'){
            return this.http.post('https://euc1api.quicktest.ai/script/uploadAudio', params);
        }else{
         return this.http.post('https://api.quicktest.ai/script/uploadAudio', params);
        }
        //  return this.post(`/script/uploadAudio`,params)
    }
    uploadWebFile(params){
        var region = this.userQuery.getRegion();
        if(region=='eu-central-1'){
            return this.http.post('https://euc1api.quicktest.ai/script/uploadAnyFile', params);
        }else{
        return this.http.post('https://api.quicktest.ai/script/uploadAnyFile', params);
        }
       //  return this.post(`/script/uploadAnyFile`,params)
   }
   downloadWebFile(fileName){
    const userToken = this.userQuery.getToken();
    // console.log(userToken)
    var region = this.userQuery.getRegion();
        if(region=='eu-central-1'){
            var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
            return this.http.get('https://euc1api.quicktest.ai/script/downloadAnyFile?fileName='+fileName, {headers:headers_object,responseType:'blob'});
        }else{
    var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);

    return this.http.get('https://api.quicktest.ai/script/downloadAnyFile?fileName='+fileName, {headers:headers_object,responseType:'blob'});
        }
    }
    deleteAudioUrl(params){
        var region = this.userQuery.getRegion();
        if(region=='eu-central-1'){
            return this.http.post('https://euc1api.quicktest.ai/script/deleteAudio', params);
        }else{
        return this.http.post('https://api.quicktest.ai/script/deleteAudio', params);
        }
    }
    getSubOrganizationListByUser(clientId){
        return this.query(`/userProfile/getSubOrganizationListByUser?userId=${clientId}`)
    }
    listWebUIActions(){
        return this.query(`/script/listWebUIActions`)
    }
    listGenesysOnPremActionList(){
        return this.query(`/sprint/getGenesysOnPremActionList`)
       }
    listXrayClientDetails(){
        return this.query(`/userProfile/listXrayClientDetails`)
    }
    addUpdateXrayClientDetails(params){
        return this.post(`/userProfile/addUpdateXrayClientDetails`,params)
    }
    deleteXray(id){
        return this.delete(`/userProfile/deleteXrayClientDetails?id=${id}`)
    }
    getXrayClientDetails(id){
        return this.query(`/userProfile/getXrayClientDetails?id=${id}`)
    }
    getMFAfromAws(){
        return this.query(`/getMFAfromAws`)
    }
    getchildListFolder(id){
        return this.query(`/script/listChildFolder?folderId=${id}`)
    }
    addUpdateComments(params) {
        return this.post('/sprint/addUpdateComments' ,params);
    }
    getCommentsByClientId(id){
        return this.query(`/sprint/getCommentsByClientId?clientId=${id}`)
    }
    loginHistory(){
        return this.query(`/userProfile/listUserAuthEvent`)
    }
    getNiceDisposition(params) {
        return this.post('/sprint/getNiceDisposition' ,params);
    }
    addUpdateCommentsFolder(params){
        return this.post(`/foldersprint/addUpdateComments`,params)
    }
    addUpdateCommentsFolderScript(params){
        return this.post(`/folderscript/addUpdateComments`,params)
    }
    getAllSprintsByScript(id){
        return this.query(`/sprint/getAllSprintsByScript?scriptId=${id}`)
    }
    editDataDrivenFile(params){
        var region = this.userQuery.getRegion();
        if(region=='eu-central-1'){
            return this.http.post('https://euc1api.quicktest.ai/userProfile//editDataDrivenFile', params);
        }else{
        return this.http.post('https://api.quicktest.ai/userProfile//editDataDrivenFile', params);
        }
    }
    getSubOrgReport(){
        return this.query('/report/getSubOrgReport')
    }
    UpdateMfaData(state){
        return this.query('/updateMFAToAws?enableMFA='+state)
    }
    listScriptByChannelPref(channelId){
        return this.query('/script/listScriptByChannelPref?channelId=' +channelId);
    }
    downloadDataDriveFile(id){
        const userToken = this.userQuery.getToken();
        // console.log(userToken)
        var region = this.userQuery.getRegion();
        if(region=='eu-central-1'){
            var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);

            return this.http.get('https://euc1api.quicktest.ai/userProfile/downloadDataDriveFile?id='+id, {headers:headers_object,responseType:'blob'});
        }else{
        var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);

        return this.http.get('https://api.quicktest.ai/userProfile/downloadDataDriveFile?id='+id, {headers:headers_object,responseType:'blob'});
        }
    }
    getOrgMFA(){
        return this.query('/getOrgMFA');
    }
    listGenesysLoginURL(){
        return this.query('/genesysInfo/listGenesysLoginURL');
    }
    listGenesysOauthDetails(){
        return this.query('/userProfile/listGenesysOauthDetails');
    }
    addUpdateGenesysOauthDetails(params){
        return this.post(`/userProfile/addUpdateGenesysOauthDetails`,params)
    }
    getGenesysOauthDetails(id){
        return this.query(`/userProfile/getGenesysOauthDetails?id=${id}`)
    }
    deleteGenesysOauthDetails(id){
        return this.delete(`/userProfile/deleteGenesysOauthDetails?id=${id}`)
    }
    getFlowName(flowType){
        return this.query(`/ivrTree/getFlowName?flowType=${flowType}`)
    }
    listAWSConnectDetails(){
        return this.query(`/userProfile/listAWSConnectDetails`)
    }
    addUpdateAWSConnectDetails(params){
        return this.post(`/userProfile/addUpdateAWSConnectDetails`,params)
    }
    addUpdateAWSConnectForVoiceOutbound(params){
        return this.post(`/userProfile/addUpdateAmazonConnectDetails`,params)
    }
    deleteAmazonConnectDetails(id){
        return this.delete(`/userProfile/deleteAmazonConnectDetails?id=${id}`)
    }
    listAWSConnectForVoiceOutbound(){
        return this.query(`/userProfile/listAmazonConnectDetails`)
    }
    PinPointProjectDetails(amazonConnectId){
        return this.query(`/userProfile/getPinPointProjectDetails?amazonConnectId=${amazonConnectId}`)
    }
    segmentDetails(pinpointId,amazonConnectId){
        return this.query(`/userProfile/getSegmentDetails?pinpointId=${pinpointId}&amazonConnectId=${amazonConnectId}`)
    }
    deleteAWSConnectDetails(id){
        return this.delete(`/userProfile/deleteAWSConnectDetails?id=${id}`)
    }
    getAWSConnectDetails(id){
        return this.query(`/userProfile/getAWSConnectDetails?id=${id}`)
    }
    getAWSConnectForVoiceOutbound(id){
        return this.query(`/userProfile/getAmazonConnectDetails?id=${id}`)
    }
    addUpdateChatBotDetails(params){
        return this.post(`/chatBotDetails/addUpdateChatBotDetails`,params)
    }
    addUpdateElastic(parmas){
        return this.post(`/userProfile/addUpdateElasticCXEnvDetails`,parmas)

    }
    addUpdateUserElasticDetails(parmas){
        return this.post(`/userProfile/addUpdateElasticCXEnvAdvancedDetails`,parmas)

    }
    getlistElasticCXEnvDetails(){
        return this.query(`/userProfile/listElasticCXEnvDetails`)

    }
    getlistElasticCXEnvUserDetails(){
        return this.query(`/userProfile/listElasticCXEnvAdvancedDetails`)

    }
    deleteElastic(id){
        return this.delete(`/userProfile/deleteElasticCXEnvDetails?id=${id}`)

    }
    deleteElasticUserDetails(id){
        return this.delete(`/userProfile/deleteElasticCXEnvAdvancedDetails?id=${id}`)

    }
    getDetailsElastic(id){
        return this.query(`/userProfile/getElasticCXEnvDetails?id=${id}`)

    }
    getDetailsUserAgentElastic(id){
        return this.query(`/userProfile/getElasticCXEnvAdvancedDetails?id=${id}`)

    }
    listChatBotDetails(){
        return this.query(`/chatBotDetails/listChatBotDetails`)
    }
    getChatBotDetails(id){
        return this.query(`/chatBotDetails/getChatBotDetails?id=${id}`)
    }
    deleteChatBotDetails(id){
        return this.delete(`/chatBotDetails/deleteChatBotDetails?id=${id}`)
    }
    getEmailCannedLibraries(id){
        return this.query(`/emailCannedResponse/getEmailCannedLibraries?genesysDetailId=${id}`)
    }
    getEmailCannedResponse(genesysDetailId,libraryId){
        return this.query(`/emailCannedResponse/getEmailCannedResponse?genesysDetailId=${genesysDetailId}&libraryId=${libraryId}`)
    }
    listChatbotTypes(){
        return this.query(`/chatBotDetails/listChatbotTypes`)
    }
    //added on 22nd Aug 2024 by Rashmi
    createProfileBot2Bot(params){
        return this.post('/chatBot/botToBotTesting', params);
    }
    getProfileBot2BotbyId(id){
        return this.query(`/chatBot/botToBotTesting/${id}`)
    } 
    getProfileBot2BotbyList(){
        return this.query('/chatBot/botToBotTesting/list')
    }
    deleteProfileBot2Bot(id){
        return this.delete(`/chatBot/botToBotTesting/delete/${id}`)
    }
    getScenerios(params){
        return this.post('/chatBot/getScenarios', params);
    }
    bot2botStartChat(params){
        return this.post('/chatBot/chat/start', params); 
    }
    bot2botTableScenario(params){
        return this.post('/chatBot/report/botToBot/', params); 
    }
    bot2botReprtHistory(params){
        return this.post('/chatBot/report/botToBot/message', params); 
    }
      
    bot2botSendMessage(params){
        return this.post('/chatBot/chat/sendMessage', params); 
    }
    bot2botStopChat(executionId){
        return this.post(`/chatBot/chat/stop/${executionId}`); 
    }
    getTranscriptStatus(clientId){
        return this.query(`/sprint/getTranscriptStatus?clientId=${clientId}`)
    }
    getJiraProjects(params){
        return this.post(`/jira/getJiraProjects`,params)
    }
    //added on 6th november
    getlistofGenesysWeb(){
       return this.query('/genesysInfo/listGenesysWebMsgURL') 
    }
    getJiraIssueTypesByProjectKey(params){
        return this.post(`/jira/getJiraIssueTypesByProjectKey`,params)
    }
    getTestKeyForScript(scriptId){
        return this.query(`/script/getTestKeyForScript?scriptId=${scriptId}`)
    }
    getNiceSkillList(params){
        return this.post(`/sprint/getNiceSkillList`,params)
    }
    getNiceCallingList(params){
        return this.post(`/sprint/getNiceCallingList`,params)
    }
    //after fixing login validation applied two / before sprint on 4/12
    uploadJsonFile(params){
        var region = this.userQuery.getRegion();
        if(region=='eu-central-1'){
            return this.http.post('https://euc1api.quicktest.ai/sprint/uploadJsonFile',params)
        }else{
        return this.http.post('https://api.quicktest.ai/sprint/uploadJsonFile',params)
        }
    }
    downloadFile(FileName){
        return this.query(`/sprint/downloadFile?s3FileName=${FileName}`)
    }
    getReportBySubOrgName(subOrgName){
        return this.query(`/report/getReportBySubOrgName?subOrgName=${subOrgName}`)
    }
    addUpdateHybridClientAppDetails(params){
        return this.post(`/userProfile/addUpdateHybridClientAppDetails`,params)
    }
    getHybridClientAppDetails(id){
        return this.query(`/userProfile/getHybridClientAppDetails?id=${id}`)
    }
    deleteHybridClientAppDetails(id){
        return this.delete(`/userProfile/deleteHybridClientAppDetails?id=${id}`)
    }
    deleteProfileImg(){
        return this.delete(`/user/deleteUserImage`)
    }
    listAWSGitDetails(){
        return this.query(`/userProfile/listAWSGitDetails`)
    }
    //added on 15/02/24
    listRepoName(repositoryId){
        return this.query(`/userProfile/getGitRepoList?repositoryId=${repositoryId}`)
    }
    listBranchNAme(repoName,repositoryId){
        return this.query(`/userProfile/getGitBranches?repositoryName=${repoName}&repositoryId=${repositoryId}`)
    }
    deleteAWSGitDetails(id){
        return this.delete(`/userProfile/deleteAWSGitDetails?id=${id}`)
    }
    addUpdateAWSGitDetail(params){
        return this.post(`/userProfile/addUpdateAWSGitDetail`,params)
    }
    getAWSGitDetails(id){
        return this.query(`/userProfile/getAWSGitDetails?id=${id}`)
    }
    getNoiseListing(){
        return this.query(`/sprint/getNoiseListing `)
    }
    getBrowserCallingDetails(clientId){
        return this.query(`/browserCalling/getBrowserCallingDetails?browserCallingId=${clientId}`)
    }
    listHybridClientAppDetails(){
        return this.query(`/userProfile/listHybridClientAppDetails`)
    }
    checkScriptUpdateFromExecutionPageAllowed(sprintId){
        return this.query(`/sprint/checkScriptUpdateFromExecutionPageAllowed?sprintId=${sprintId}`)
    }
    updateScriptMessageByStep(params){
        return this.post(`/sprint/updateScriptMessageByStep`,params)
    }
    getAWSRegion(){
        return this.query(`/chatBotDetails/getAWSRegion`)
    }
    listSelectedClientApps(sprintId){
        return this.query(`/sprint/listSelectedClientApps?sprintId=${sprintId}`)
    }
    getUserList(){
        return this.query(`/userProfile/getUserList`)
    } 
    listNoiseFile(){
        return this.query(`/userProfile/listNoiseFile`)
    } 
    uploadNoiseAudio(params){
        var region = this.userQuery.getRegion();
        if(region=='eu-central-1'){
            return this.http.post('https://euc1api.quicktest.ai/userProfile/uploadNoiseAudio', params);
        }else{
         return this.http.post('https://api.quicktest.ai/userProfile/uploadNoiseAudio', params);
        }
        //  return this.post(`/script/uploadAudio`,params)
    }
    deleteNoiseAudio(url){
        return this.post(`/userProfile/deleteNoiseAudio?url=${url}`)
    }
    addUpdateNoiseFile(params){
        return this.post(`/userProfile/addUpdateNoiseFile`,params)
    }
    deleteNoiseDetails(id){
        return this.delete(`/userProfile/deleteNoiseDetails?id=${id}`)
    }
    getNoiseDetails(id){
        return this.query(`/userProfile/getNoiseDetails?id=${id}`)
    } 
    getBrowserCallingSuggestionNumbers(recent){
        return this.query(`/browserCalling/getBrowserCallingSuggestionNumbers?type=${recent}`)
    } 
    getEmailNotificationConfigList(){
        return this.query(`/userProfile/getEmailNotificationConfigList`)
    } 
    getEmailType(){
        return this.query(`/userProfile/getEmailType`)
    } 
    saveEmailNotification(params){
        return this.post(`/userProfile/saveEmailNotification`,params)
    }
    deleteEmailNotificationConfig(id){
        return this.delete(`/userProfile/deleteEmailNotificationConfig?id=${id}`)
    }
    getLoadInboundInteractionDetails(id){
        return this.query(`/sprint/getLoadInboundInteractionDetails?loadStartSprintId=${id}`)
    } 
    getLoadImageList(clientId,agentName){
        return this.query(`/sprint/getLoadImageList?agentName=${agentName}&clientId=${clientId}`)
    }
    getTimeZone(){
        var region = this.userQuery.getRegion();
        var baseApi2=region=='eu-central-1'?this.baseApi1:this.baseApi
       return this.http.get(baseApi2+'/getTimeZone',{responseType:'text'});
    }
    updateTimeZone(TimeZone){
        return this.post(`/updateTimeZone?timeZone=${TimeZone}`,)
    }
    addUpdatePauseScheduleGroups(params){
        return this.post(`/userProfile/addUpdatePauseScheduleGroups`,params)
    }
    sendRapidOnboardingEmail(params){
        return this.newpost(`/defaultSetup/uploadTestInfo/`, params)
    }

    listPauseScheduleGroups(){
        return this.query(`/userProfile/listPauseScheduleGroups`)
    }
    deletePauseScheduleGroups(id){
        return this.delete(`/userProfile/deletePauseScheduleGroups?id=${id}`)
    }
    getPauseScheduleGroup(id){
        return this.query(`/userProfile/getPauseScheduleGroup?id=${id}`)
    }
    getIvrTreeHist(params){
        return this.post(`/ivrTree/getIvrTreeHist`,params)
    }
    getInteractionAgentReportByLoadStartSprintId(id){
        return this.query(`/sprint/getInteractionAgentReportByLoadStartSprintId?loadStartSprintId=${id}`)
    }
    downloadAgentInfoTemplate(id) {
        const userToken = this.userQuery.getToken();
        var region = this.userQuery.getRegion();
        if(region=='eu-central-1'){
            var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);

            return this.http.get('https://api.quicktest.ai/sprint/downloadAgentInfoTemplate?subChannelName=${id}', {headers:headers_object,responseType:'blob'});
        }else{
        // console.log(userToken)
        var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);

        return this.http.get(`https://api.quicktest.ai/sprint/downloadAgentInfoTemplate?subChannelName=${id}`, {headers:headers_object,responseType:'blob'});
        }
    }
    listGenesysLabDetails(clienAppId){
        return this.query(`/userProfile/listGenesysLabDetails?clienAppId=${clienAppId}`)
    }
    SprintlistGenesysLabDetails(clienAppId){
        return this.query(`/userProfile/listGenesysLabDetailsValidated?clienAppId=${clienAppId}`)
    }
    deleteGenesysLabDetails(id){
        return this.delete(`/userProfile/deleteGenesysLabDetails?id=${id}`)
    }
    addUpdateGenesysLabDetails(item){
        return this.post('/userProfile/addUpdateGenesysLabDetails',item)
    }
    getGenesysLabDetails(id){
        return this.query(`/userProfile/getGenesysLabDetails?id=`+id)

    }
    dashboardAutomaticSprint(testType){
        return this.query(`/dashboardSprint/dashboardAutomaticSprint?testType=${testType}`,)
    }
    dashboardFolderSprints(){
        return this.query(`/dashboardSprint/dashboardFolderSprints`,)
    }
    listUserNotification(){
        return this.query(`/userSubscribeNotification/listUserNotification`,)
    }
    listAllNotification(){
        return this.query(`/userSubscribeNotification/listAllNotification`,)
    }
    saveUserNotification(param){
        return this.post(`/userSubscribeNotification/saveUserNotification`,param)
    }
    latestNotifications(){
        return this.query(`/mainNotification/latestNotifications`)
    }
    updateFlag(notificationId){
        return this.post(`/mainNotification/updateFlag?notifId=${notificationId}`)
    }
    markAllRead(){
        return this.post(`/mainNotification/markAllRead?flag=${true}`)
    }
    initiateChat(param){
        return this.post(`/chatBot/initiateChat`,param)
    }
    replyChat(param){
        return this.post(`/chatBot/replyChat`,param)
    }
    terminateChat(param){
        return this.post(`/chatBot/terminateChat`,param)
    }
    autopilotMessage(customerId){
        return this.query(`/autopilotMessage/listMessageData?customerId=${customerId}`)
    }
    chatBotHistory(param){
        return this.post(`/chatBot/history`,param)
    }
    Bot2BotHistory(param){
        return this.post(`/chatBot/botToBot/history`,param)
    }
    contactcentertypes(){
        return this.query(`/contactCentres/contactcentertypes `)
    }
    direction(){
        return this.query(`/contactCentres/direction `)
    }
    contactcenterinfo(name){
        return this.query(`/contactCentres/contactcenterinfo/name?name=${name}`)
    }
    getEntityList(centreType,oauthId,entryPoint){
        return this.query(`/contactCentres/getEntityList?contactCentreType=${centreType}&oauthId=${oauthId}&entryPoint=${entryPoint}`)
    }
    listOauthAccounts(type){
        return this.query(`/contactCentres/listOauthAccounts?contactCentreType=${type}`);
    }
    addUpdateJob(param){
        return this.post(`/contactCentres/addUpdateJob `,param)
    }
    listProactiveMonitoring(){
        return this.query(`/contactCentres/listProactiveMonitoring`);
    }
    deleteProactiveMonitoring(id){
        return this.delete(`/contactCentres/deleteProactiveMonitoring?id=${id} `)
    }
    getProactiveMonitoring(id){
        return this.query(`/contactCentres/getProactiveMonitoring?id=${id}`);
    }
    changeScheduleState(id,state){
        return this.query(`/contactCentres/changeScheduleState?id=${id}&state=${state}`);
    }
    historicData(param){
        return this.post(`/contactCentres/historicData`,param);
    }
    getWidgetList(){
        return this.query(`/customDashboard/getWidgetList`);
    }
    addUpdateDashboard(param){
        return this.post(`/customDashboard/addUpdateDashboard`,param);
    }
    getDashboardList(){
        return this.query(`/customDashboard/getDashboardList`);
    }
    getDashboardDetail(id){
        return this.query(`/customDashboard/getDashboardDetail?id=${id}`);
    }
    deleteDashboard(id){
        return this.delete(`/customDashboard/deleteDashboard?id=${id}`);
    }
    getInvoiceDetailsV2(params): Observable<any[]> {
        return this.post('/report/getInvoiceDetailsV2', params);
    }
    getAllwebexagent(){
        return this.query(`/api/webex-agent`)
    }
    getAllwebexconfig(){
        return this.query(`/api/webex-config`)
    }
    addUpdateWebexAgentDetails(params){
        return this.post(`/api/webex-agent`,params)
    }
    addUpdateWebexconfigDetails(params){
        return this.post(`/api/webex-config`,params)
    }
    Updatewebexagentdetail(id){
        return this.query(`/api/webex-agent/id?id=${id}`)
    }
    Updatewebexconfigdetail(id){
        return this.query(`/api/webex-config/id?id=${id}`)
    }
    delwebexagent(id){
        return this.delete(`/api/webex-agent/id?id=${id}`)
    }
    delwebexconfig(id){
        return this.delete(`/api/webex-config/delete?id=${id}`)
    }
    getwebexDetails(param){
        return this.post(`/teams`,param)
    }
    listwebexregin(){
        return this.query('/api/webex-config/regions');
    }
}