import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-drop-down-folder',
  templateUrl: './drop-down-folder.component.html',
  styleUrls: ['./drop-down-folder.component.scss']
})
export class DropDownFolderComponent implements OnInit {
  @ViewChild('panelDOM') panelDOM: ElementRef;
  @ViewChild('container') container: ElementRef;

  @Input() trigger;
  showMenu = false;

  constructor() { }

  ngOnInit(): void {
  }




  @HostListener('document:click', ['$event'])
  clickout(event:MouseEvent) {
    if (this.showMenu && !this.panelDOM.nativeElement.contains(event.target)) {
      this.toggleMenu();
      //this.container.nativeElement.style.display='none'
    }
  }
  


  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

}
