import { Component, EventEmitter, Input, NgModule, OnChanges, OnInit, Output, SimpleChanges, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ExcelService } from 'src/app/services/excel.service';
import { ReportsdetailsService } from 'src/app/services/reportsdetails.service';
import { DatePickerComponent } from '../date-picker/date-picker.component';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit , OnChanges {
  @Input() disableInit = false;
  @Input() setDate = null ;
  @Input() timeZone= null ;
  @Output() change = new EventEmitter<any>();
  @Output() changeTimeZone= new EventEmitter<any>();
  @Input() setTimeZone = null ;
  setTimeForm: FormGroup;
  setTimeDate:any
  // @Output() timeEvent = new EventEmitter();
  timeZoneLists:String[];
  timeZoneList=[{timeZone:'UTC +01:00',countryCode:'Europe/London',name:'IST'},{timeZone:'UTC -04:00',countryCode:'America/New_York',name:'GMT'},{timeZone:'UTC +08:00',countryCode:'Asia/Singapore',name:'CET'}
  ,{timeZone:'UTC +05:30',countryCode:'Asia/Kolkata',name:'CEST'}]
  timeZoneSource:any[];
  picker=false
  constructor(private propService:ReportsdetailsService) {
    let date1
    var date2
    console.log(this.setTimeDate)
    if( this.setDate){
       this.setTimeDate= new Date(this.setDate);
       console.log(this.setTimeDate)
    }else{
      date1= new Date();
      console.log(date1)
      date2= moment.tz(date1, this.propService.timeZone).format("MM/DD/YYYY HH:mm:ss")
       this.setTimeDate= new Date(date2);
      console.log(this.setTimeDate);
      // this.timeEvent.emit(this.setTimeDate)
    }
    this.setTimeForm = new FormGroup({
      timeHr: new FormControl(this.setTimeDate.getHours() > 12 ? this.setTimeDate.getHours() - 12 : this.setTimeDate.getHours()),
      timeMin: new FormControl(this.setTimeDate.getMinutes() <15?0:this.setTimeDate.getMinutes()<30?15:this.setTimeDate.getMinutes() <45?30:this.setTimeDate.getMinutes()<60?45:0),
      isPm: new FormControl(false),
      timeZone: new FormControl(this.setTimeDate.getHours() > 12 ? 12 : 0),
    })

}

  ngOnInit(): void {
    this.close();
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if(changes){
    if(changes.setDate?.currentValue &&  !changes.setTimeZone?.currentValue){
      let  date
      this.setDate= changes.setDate.currentValue;
      date = new Date(changes.setDate.currentValue)

      if(this.setTimeZone){
        this.setTimeDate= new Date(this.setDate);
        date=this.setTimeDate;
       console.log(this.setTimeDate);
      }
      console.log(this.timeZone)
      this.setTimeForm.controls.timeHr.setValue(date.getHours() > 12 ? date.getHours() - 12 : date.getHours());
      this.setTimeForm.controls.timeMin.setValue(date.getMinutes() <15?0:date.getMinutes()<30?15:date.getMinutes() <45?30:date.getMinutes()<60?45:0)
      this.setTimeForm.controls.timeZone.setValue(this.timeZone ==="am"? 0:12 || date.getHours() > 12 ? 12 : 0 );
      this.setTimeForm.controls.isPm.setValue(false);

    }
    if(changes.setTimeZone?.currentValue !== changes.setTimeZone?.previousValue){
      let date1= new Date();
      console.log(date1)
      this.setTimeZone= this.propService.timeZone;
      const date2= moment.tz(date1, this.propService.timeZone).format("MM/DD/YYYY HH:mm:ss")
       this.setTimeDate= new Date(date2);
      console.log(this.setTimeDate);
      this.setTimeForm.controls.timeHr.setValue(this.setTimeDate.getHours() > 12 ? this.setTimeDate.getHours() - 12 : this.setTimeDate.getHours());
      this.setTimeForm.controls.timeMin.setValue(this.setTimeDate.getMinutes() <15?0:this.setTimeDate.getMinutes()<30?15:this.setTimeDate.getMinutes() <45?30:this.setTimeDate.getMinutes()<60?45:0)
      this.setTimeForm.controls.timeZone.setValue((this.setTimeDate.getHours()>12)|| (this.setTimeDate.getHours()==12 && this.setTimeDate.getMinutes()>0)? 12 : 0 );
      this.setTimeForm.controls.isPm.setValue(false);
      console.log(this.setTimeForm.controls.timeZone.value)
    }
    this.change.emit(this.setTimeForm.value.timeHr + ':' + this.setTimeForm.value.timeMin + ':00');
  }
  }

  hourUp() {
    const data = this.setTimeForm.value;
    if (data.timeHr >= 12) {
      return
    }

    let newHr = data.timeHr + 1;
    this.setTimeForm.controls.timeHr.patchValue(newHr);
    this.close();
  }

  hourDown() {
    const data = this.setTimeForm.value;
    if (data.timeHr <= 0) {
      return
    }

    let newHr = data.timeHr - 1;
    this.setTimeForm.controls.timeHr.patchValue(newHr);
    this.close();
  }

  minUp() {
    const data = this.setTimeForm.value;
    if (data.timeMin >= 45) {
       let newHr = 45;
       this.setTimeForm.controls.timeMin.patchValue(newHr);
       console.log(this.setTimeForm.controls.timeMin.patchValue(newHr))
      return
    }
    else{
      let newHr = data.timeMin + 15;
     this.setTimeForm.controls.timeMin.patchValue(newHr);
    }
    
    this.close();
  }

  minDown() {
    const data = this.setTimeForm.value;
    if (data.timeMin <= 0) {
      let newHr =0;
      this.setTimeForm.controls.timeMin.patchValue(newHr);
      return
    }
    else{
      let newHr = data.timeMin - 15;
     this.setTimeForm.controls.timeMin.patchValue(newHr);
    }
    
    this.close();
  }

  setZone(val) {
    this.setTimeForm.controls.timeZone.patchValue(val);
    if( val=== 0 ){
      this.changeTimeZone.emit('am')

    }else{
      this.changeTimeZone.emit('pm')

    }
    this.close();
  }

  close() {
    let hour = this.setTimeForm.value.timeHr;
    if (hour < 12) {
      hour += this.setTimeForm.value.timeZone;
    } else if (this.setTimeForm.value.timeZone < 12) {
      hour = 0;
    }
    this.change.emit(hour + ':' + this.setTimeForm.value.timeMin + ':00');
  }
}
