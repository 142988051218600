import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[watchDomTree]'
})
export class DomChangesDirective implements OnInit {

  constructor(private elmentRef: ElementRef) {
  
   }
  ngOnInit(): void {
      this.regiesterDomChangedEvent(this.elmentRef.nativeElement);
  }
   regiesterDomChangedEvent(elmentRef){
    const observer  = new MutationObserver((list)=>{
      const evt = new CustomEvent('dom-changed',{
        detail:list, bubbles:true
      })
      elmentRef.dispatchEvent(evt);
    
    })
    const attributes= false;
    const childList = true ;
    const subtree= true ;
    observer.observe(elmentRef, {attributes, childList, subtree})
   }


}
