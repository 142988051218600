<div class="common-form-input">
    <div class="label" *ngIf="label">{{ label }} <span class="required-text" *ngIf="required">
        <span style="color: #ff0000;
        font-size: 19px;">*</span> </span>
        </div>
    <ng-content select="select"></ng-content>
    <div class="suffix">
        <ng-content select="[suffix]"></ng-content>
    </div>
    <div class="field-error" *ngIf="error && error.errors && error.touched">{{ getError }}</div>
</div>