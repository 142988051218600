import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { requestsService } from 'src/app/services/requests/requests.service';

@Component({
  selector: 'script-busy',
  templateUrl: './script-busy.component.html',
  styleUrls: ['./script-busy.component.scss']
})
export class ScriptBusyComponent implements OnInit {
  allSprintListsFilterd=[];
  busy=false
  busy1=false
  constructor(public automatorApi:requestsService,public dialogRef: MatDialogRef<ScriptBusyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
  }

  ngOnInit(): void {
    this.getAllSprintsByScript()
  }
  getAllSprintsByScript(){
    this.automatorApi.getAllSprintsByScript(this.data).subscribe(res=>{
      this.allSprintListsFilterd=res
      if(this.allSprintListsFilterd.length>0){
        this.busy=true
      }else{
      this.busy1=true
      }
    })
  }
  FormateDate(date1:string){
  
 
    if(date1 !=null){
   
       var newDate= new Date(date1 + ' UTC');
       // console.log(newDate)
       return newDate.toString();
    }
    else{
      return '';
    }
     
   }
   closeDialog() {
    this.dialogRef.close();
}
}
