<mat-expansion-panel cdkDrag2 class="expande-panel" style="transform: translate3d(5px 5px 5px);">    

<mat-expansion-panel-header  (click)="childData(row)">
    <div body  class="data-table1" style="width: 100%;">
      <div class="row list" >
        <div class="header cell" style="display: flex">
          <img src="assets/icons/folder.png" style="color: #F8D775;margin-right: 10px;width: 28px;">
          <div style="padding-top: 4px;">{{ row?.folderName}}</div>
        </div>
        <div class="header cell">{{row?.channelName}}</div>
        <div class="cell center" style="float:right;margin: 0px;">
            <!-- <div style="position: absolute;">
          <app-dropdown style="left: -10px;">
              <div menu class="menu-options" > -->
                  <img class="item" title="Edit folder" src="assets/icons/edit.svg" (click)="nameFolder(row.id, row.parentFolderId)"  style="margin-right: 7px;">  
                <img class="item" title="Rename folder" src="assets/icons/edit-folder.png" (click)="RenameFolder(row.parentFolderId,row.id,row.folderName,row.channelId)" style="width:20px;margin-right: 7px;">
                <div class="item" title="Create sub folder" style="margin-left: 4px;font-size:25px;margin-right: 7px;" (click)="createFolder(row.parentFolderId,row.id)">+</div>
                  <img class="item" title="Delete folder" src="assets/icons/delete.svg"  (click)="deletefolder(row,row.folderName)"style="margin-left: 4px;margin-right: 7px;">
              <!-- </div>
          </app-dropdown>
        </div> -->
        </div>
      </div>
    </div>
  </mat-expansion-panel-header>

<ng-container *ngIf="this.row.scriptList?.length > 0">
  <div class="sprint_list">

    <div body class="data-table" id="childData">
      <div class="row1">
        <div class="header cell">
          <span>Script Name</span>
        </div>
        <div class="header cell">
          <span>Created Date & Time</span>
        </div>
        <div class="header cell">
          Channel
        </div>
        <div class="header cell">
          User Name
        </div>
        <div class="header cell">
          Action
        </div>
      </div>
      <div class="area" id="filterResults1" cdkDropList #doneList1="cdkDropList" [cdkDropListData]="filterResults"
        [cdkDropListConnectedTo]="[doneList]">
        <div class="row1 list" *ngFor="let item of this.row.scriptList; let i = index" cdkDrag2>
          <div class="header cell" style="word-wrap: break-word; word-break: break-all;">
            {{ item?.scriptName}}
          </div>
          <div class="cell"  style="word-wrap: break-word; word-break: break-all;">
            {{ FormateDate(item?.createdOn, countryCode) | date:'MM-dd-yyyy HH:mm:ss'}}
          </div>
          <div class="cell"  style="word-wrap: break-word; word-break: break-all;">
            {{ item?.channelName }}
          </div>
          <div class="cell"  style="word-wrap: break-word; word-break: break-all;">
            {{ item?.emailId }}
          </div>
          <div class="cell">
            <!-- <div style="position: absolute;">
            <app-dropdown>
                <div menu class="menu-options"> -->
                 
                    <img class="item" title="edit" src="assets/icons/edit.svg" (click)="edit(item.id, row.id)"
                    style="width: 20px; height: 19px;">
                    <img class="item" src="assets/icons/delete.svg" style="width: 16px; margin-left: 10px;"
                    (click)="deleteScript($event, item.id, item?.scriptName)">
                  <img class="item" title="save as" src="assets/icons/save-as.png" (click)="saveAs(item.id, row.id)"
                    style="width: 20px; height: 19px; margin-left: 10px;">
                  <mat-icon class="item" style="margin-left: 10px;" title="Sprints Associated" (click)="sprintList(item.id)">notes</mat-icon>
                <!-- </div>
            </app-dropdown>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="this.row.children?.length > 0">
    <!-- <mat-expansion-panel *ngFor="let children of row?.children" (click)="childData(row)" cdkDrag style="transform: translate3d(5px 5px 5px);">    
                                  xxxx -->
        <app-folder-item *ngFor="let children of row.children" (DeletedEvent)="deletedEventFunc($event)" [row]="children" [selectedid]="selectedid"></app-folder-item>
         
    </ng-container>
</mat-expansion-panel><app-modal [open]="showConfirmDel1" [type]="'warn'" [primaryBtn]="'Delete'" [body]="delModalBody"
[placeholder]="'del-sprint-modal-placeholder.svg'" (close)="confirmDel1($event)">
</app-modal>
<app-modal [open]="showConfirmDel2" [type]="'warn'" [primaryBtn]="'Delete'" [body]="delModalBody"
    [placeholder]="'del-sprint-modal-placeholder.svg'" (close)="confirmDel3($event)">
</app-modal>