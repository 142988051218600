<div class="common-page-container animate fade-in">
    <div>
        <div class="common-page-header">
            <app-heading [title]="title" [size]="20"></app-heading>
        </div>
        <div class="bread-nav">
            <ng-content select=[navigator]></ng-content>
        </div>
    </div>

    <div class="common-body-wrapper">
        <ng-content select="[body]"></ng-content>
    </div>
</div>