<div class="mwa-date-picker" #panelDOM>
    <!-- <div class="overlay-wrapper" *ngIf="showPicker" (click)="close(true)"></div> -->
    <div class="wrapper">
        <div class="box-name" *ngIf="title">{{ title }}</div>
        <div class="date-picker">
            <div class="set-month">
                <div class="nav" (click)="prevMonth()">
                    <img class="ico" src="/assets/icons/chevron-left.svg">
                </div>
                <div>{{ getLabel }}</div>
                <div class="nav" (click)="nextMonth()">
                    <img class="ico right" src="/assets/icons/chevron-left.svg">
                </div>
            </div>
            <div class="calendar">
                <div class="head">Su</div>
                <div class="head">Mo</div>
                <div class="head">Tu</div>
                <div class="head">We</div>
                <div class="head">Th</div>
                <div class="head">Fr</div>
                <div class="head">Sa</div>
                <div class="c-cell" *ngFor="let item of days" [class.data]="item>0"
                    [class.active]="month+'-'+item+'-'+year === selection"
                    [class.disabled]="(disableFutureDates && today  < (year+''+month+''+item)) || (disablePastDates && today > (year+''+month+''+item))">
                    <div *ngIf="item>0" (click)="pickDate(item)">{{item}}</div>
                </div>
            </div>
        </div>
    </div>
</div>