
<!-- <div mat-dialog-title style="float: right; font-weight: 700; cursor: pointer;margin: 0px;"  (click)="close()">X</div>
<div style="width: 100%;float:left;">

<mat-dialog-content>
    
</mat-dialog-content>

</div> -->
<div class="body-bg">
    <img class="placeholder" src="../../assets/illustrations/new-sprint-modal-placeholder.svg">
    <img src="/assets/icons/close-light.svg" class="close" (click)="close()">
</div>
<div class="body-container">
    <div>
        <h3>You have unsaved changes.</h3>
    </div>
    <div class="text"><p>Are you sure you want to leave this page?</p></div>
    <div class="btn-set">
        <mat-dialog-actions>
            <button mat-button  [mat-dialog-close]="true" style="background-color: #735ae2 !important;">Yes</button>
            <button mat-button mat-dialog-close style="background-color: white !important;color:#735ae2 !important;border: 1px solid #735ae2 !important;">No</button>
        </mat-dialog-actions>
    </div>
</div>
