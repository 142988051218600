import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { requestsService } from 'src/app/services/requests/requests.service';

@Component({
  selector: 'rename-script-folder',
  templateUrl: './rename-script-folder.component.html',
  styleUrls: ['./rename-script-folder.component.scss']
})
export class RenameScriptFolderComponent implements OnInit {
  createFolder:FormGroup;
  isDirty=false;
  id:any;
  parentFolderId:any;
  btnDisabled: boolean;
  canDeactivate(): boolean {
    return !this.isDirty
   }
   channelsList=[]
  constructor(
    public dialogRef: MatDialogRef<RenameScriptFolderComponent>,
    private router: Router,
    private automatorApi: requestsService,
    private toastr: ToastrService,@Inject(MAT_DIALOG_DATA) public data: any
    ) { 
      console.log(data)
    this.createFolder = new FormGroup({
      folderName: new FormControl('',[Validators.required]),
      channelId:new FormControl('',[Validators.required])
    });
  }
  ngOnInit() {
    // console.log(this.id),
    // console.log(this.parentFolderId)
    // this.id=this.data.id,
    // console.log(this.id)
    // this.parentFolderId=this.data.val
    if(this.data){
      this.createFolder.controls.folderName.patchValue(this.data.folderName)
      this.createFolder.controls.channelId.patchValue(this.data.channelId)
    }
    this.automatorApi.getChannels().subscribe(res => {
      console.log(res)
      this.channelsList = res
    });
  }
  

  create() {
    this.isDirty=false;
    this.btnDisabled = true;
    if (Object.values(this.createFolder.controls).some(control => control.invalid) === true) {
      this.toastr.warning('Form is incomplete');
      this.btnDisabled = false;
      return;
    }
    if(this.createFolder.controls.folderName.value.trim()===""){
      this.toastr.warning("Required field is missing")
      this.btnDisabled = false;
      return;
    }
    if(this.data){
      this.automatorApi.UpdateScriptFolder ({
        'folderName':this.createFolder.value.folderName,
      'channelId':Number(this.createFolder.value.channelId),
      'id':this.data.id,
      'parentFolderId':this.data.parentFolderId
       })
      .subscribe(res => {
        this.btnDisabled = false;
        this.dialogRef.close(true);
        if(this.data.parentId==0){
          this.toastr.success(
            "Folder Updated Successfully",
          );
        }else{
          this.toastr.success(
            "Sub Folder Updated Successfully",
          );
        }
      },err => {
        if(err.message==500){
          this.toastr.error(err.message);
          this.btnDisabled = false;
        }else{
        this.toastr.error(err.message);
        this.btnDisabled = false;
        }
      });
    }else{
    this.automatorApi.updateFolderDetails({
      'folderName':this.createFolder.value.folderName,
      'channelId':Number(this.createFolder.value.channelId),
   })
      .subscribe(res => {
        this.btnDisabled = false;
        this.dialogRef.close(true);
        this.toastr.success(
          "Folder Created Successfully",
        );
      },err => {
        if(err.message==500){
          this.toastr.error(err.message);
          this.btnDisabled = false;
        }else{
        this.toastr.error(err.message);
        this.btnDisabled = false;
        }
      });}
  }

  closeDialog() {
    this.dialogRef.close();
  }


}