import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent implements OnInit {
  @Input() label;
  @Input() error;
  @Input() required;

  @Input() type = 'text';

  errorMap = {
    required: 'Required',
    email: 'Enter a valid email',
    minlength: 'Should be longer'
  };

  constructor() { }

  ngOnInit(): void {
  }

  get getError() {
    return this.errorMap[Object.keys(this.error.errors)[0]];
  }


}
