import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ExcelService } from 'src/app/services/excel.service';
import { ReportsdetailsService } from 'src/app/services/reportsdetails.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnChanges {
  @ViewChild('panelDOM') panelDOM: ElementRef;

  @Input() show = false;
  @Input() title = null;
  @Input() date;
  @Input() disableInit = false;
  @Input() disableFutureDates = false;
  @Input() disablePastDates = false;
  @Input() setTimeZone = null ;
  @Output() change = new EventEmitter<any>();
  selection: string;
  today: number;
  month: string;
  day: string;
  year: number;
  monthName: string;
  days = [];
  monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  showPicker = false;
  constructor(private propService:ReportsdetailsService) {
    // var subscriptionName= this.excel.getUpdate().subscribe
    // (message => { //message contains the data sent from service
    // var messageReceived = message;
    // console.log(messageReceived)
    // });
  }

  ngOnInit() {
    console.log(this.propService.timeZone)
    var date1= new Date();
    console.log(date1);
    console.log(date1)
    var date2= moment.tz(date1, this.propService.timeZone).format("MM/DD/YYYY HH:mm:ss");
    console.log(date2)
     const date = new Date(date2);
     this.day = ('0' + date.getDate()).substr(-2);
     this.month = ('0' + (date.getMonth() + 1)).substr(-2);
     this.year = date.getFullYear();
     this.selection = this.month + '-' + this.day + '-' + this.year;
     this.today = parseInt('' + this.year + this.month + this.day);
     this.setMonth();
     console.log(this.today)
     if (!this.disableInit) {
       setTimeout(() => this.close(true));
     }
   }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.show && !this.panelDOM.nativeElement.contains(event.target)) {
      //this.close(false);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(this.propService.timeZone)
    if(changes.setTimeZone?.currentValue !== changes.setTimeZone?.previousValue){
      this.ngOnInit();
    }

    if (changes.show) {
      this.showPicker = changes.show.currentValue;
    }
    if (changes['date'] && changes.date.currentValue !== this.selection && !changes.date.firstChange) {
      const format = changes.date.currentValue.split('-');
      if (format.length > 0) {
        const old = format[1];
        format[1] = format[0];
        format[0] = old;
      }

      const date = new Date(format);
      this.day = ('0' + date.getDate()).substr(-2);
      this.month = ('0' + (date.getMonth() + 1)).substr(-2);;
      this.year = date.getFullYear();
      this.selection = this.month + '-' + this.day + '-' + this.year;
      this.setMonth();
      // setTimeout(() => this.close());
    }
  }

  setMonth() {
    const days = new Date(this.year, parseInt(this.month), 0).getDate();
    var pos = new Date(this.month + ' ' + 1 + ' ' + this.year).getDay();
    this.days = [];
    if (pos > 0) {
      for (let i = 0; i < pos; i++) {
        this.days.push(0);
      }
    }
    for (let i = 0; i < days; i++) {
      this.days.push(('0' + (i + 1)).substr(-2));
    }
  }

  nextMonth() {
    let month = parseInt(this.month) + 1;
    if (month > 12) {
      month = 1;
      this.year++;
    }
    this.month = ('0' + month).substr(-2);
    this.setMonth();
  }

  prevMonth() {
    let month = parseInt(this.month) - 1;
    if (month < 1) {
      month = 12;
      this.year--;
    }
    this.month = ('0' + month).substr(-2);
    this.setMonth();
  }

  pickDate(day) {
    if (this.disableFutureDates && parseInt('' + this.year + this.month + day) > this.today) {
      return;
    }

    if (this.disablePastDates && parseInt('' + this.year + this.month + day) < this.today) {
      return;
    }

    this.selection = this.month + '-' + day + '-' + this.year;
    this.close(true);
  }

  get getLabel() {
    return `${this.monthList[parseInt(this.month) - 1]} ${this.year}`;
  }

  close(emit = false) {
    if (emit) {
      this.change.emit(this.selection);
    } else {
      this.change.emit();
    }
  }
}
