export class sprintFolder{
    emailAddr:string;
    folderName:string;
    id:number;
    isCollapse:boolean=false;
    deserialize(input: any): this {
        return Object.assign(this, input);
      }
}

export class ScriptFolder{
  emailAddr:string;
  folderName:string;
  id:number;
  isCollapse:boolean=false;
  children:any=[];
  scriptList:any=[];
  scriptListFiltred:any=[]
  deserialize(input: any): this {
      return Object.assign(this, input);
    }
}