import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { requestsService } from 'src/app/services/requests/requests.service';
import { CdkDragDrop, CdkDragStart, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { id } from 'date-fns/locale';
import { RenameScriptFolderComponent } from 'src/app/home/script/rename-script-folder/rename-script-folder.component';
import { MatDialog } from '@angular/material/dialog';
import { CreateScriptFolderComponent } from 'src/app/home/script/create-script-folder/create-script-folder.component';
import { ToastrService } from 'ngx-toastr';
import { ScriptBusyComponent } from 'src/app/home/script/script-busy/script-busy.component';
import { UserQuery } from 'src/app/services/user/user.query';

@Component({
  selector: 'app-folder-item',
  templateUrl: './folder-item.component.html',
  styleUrls: ['./folder-item.component.scss']
})
export class FolderItemComponent implements OnInit {
  expanded = true;
  folderServiceListfilterd: any;
  childfolderData: any;
  listScriptFolder: any
  ScriptData: any;
  delData;
  del: any
  folderServiceList: any[];
  child: any
  delModalBody: string;
  showConfirmDel1: boolean;
  showConfirmDel2: boolean;
  readAccess: boolean = false;
  writeAccess: boolean = false;
  role = null;
  @Input() row: any; // Input property to receive the row data from the parent component
  @Input() selectedid: any; // Input property to receive the selected ID from the parent component
  @Output()  DeletedEvent? = new EventEmitter();
  filterResults: any[] = []; // Your filter results
  constructor(private apiService: requestsService,
    private router: Router,
    private toastr: ToastrService, private userQuery: UserQuery,
    public dialog: MatDialog) {
    this.userQuery.getRole().subscribe((user: any) => {
      console.log(user);
      if (user) {
        this.role = user.roleName.toUpperCase() // just for make sure thet there no error on speling 
        this.readAccess = user.readAccess;
        this.writeAccess = user.writeAccess;
      }
    });
  }
  ngOnInit(): void {
    console.log(this.row, this.selectedid);
  }
  deleteScript(event: any, itemId: any, scriptName: string) {
    if (this.writeAccess == false) {
      this.toastr.error("You don't have the permission to perform this action")
      return
    } else {
      this.delData = itemId
      this.delModalBody = `Do you want to Delete <b>${scriptName}</b>?`;
      this.showConfirmDel2 = true;
    }
  }
  confirmDel3(res: any) {
    this.showConfirmDel2 = false;
    console.log(this.delData)
    if (res) {
      this.apiService.removeScript(this.delData).subscribe(res => {
        if (res.result == "Script Deleted Successfully") {
          this.toastr.success(res.result)
          this.row.scriptList = this.row.scriptList.filter((item: { id: any; }) => item.id != this.delData);
        }
        else {
          this.toastr.error('Failed to deleted Script. It is already in use.');
        }
      });
    }
  }
  confirmDel1(res: any) {
    this.showConfirmDel1 = false;
    console.log(this.delData)
    if (res) {
      this.apiService.delScriptFolder(this.delData.id).subscribe(res => {
        if (res.result) {
          console.log(this.delData);
          console.log(this.folderServiceList, this.ScriptData);
          this.DeletedEvent.emit(this.delData.id)
          
          // const indexToDelete = this.folderServiceList.findIndex((element: { id: any; }) => {
          //   return element.id === this.delData.id;
          // });
          // if (indexToDelete !== -1) {
          //   this.folderServiceList.splice(indexToDelete, 1);
          // }
          // this.folderServiceList.splice(this.delData.index, 1);

          this.toastr.success("Sub Folder Deleted Successfully"); // Changes done for QUIC-1001 - Manik 07/12/23
          // this.row. = this.folderServiceList.filter(item => item.id != this.delData);
        }
        else {
          this.toastr.error('Failed to deleted Script. It is already in use.');
        }
      });
    }
  }
  edit(folderId: any, itemId: any) {
    if (this.writeAccess == false) {
      this.toastr.error("You don't have the permission to perform this action")
      return
    } else {
      this.router.navigateByUrl('/home/script/view/' + folderId + '/edit/' + itemId)
    }
  }
  saveAs(folderId: any, itemId: any) {
    if (this.writeAccess == false) {
      this.toastr.error("You don't have the permission to perform this action")
      return
    } else {
      this.router.navigateByUrl('/home/script/view/' + folderId + '/saveas/' + itemId)
    }
  }
  deletedEventFunc(event){
    if(event){
      //this.del
      //this.deleteScript(event , sele)
        console.log(this.selectedid)
        this.getChildrenList(this.row.id)

    
    }
  }
  FormateDate(date1: string) {

    if (date1 != null) {

      var newDate = new Date(date1 + ' UTC');
      // console.log(newDate)
      return newDate.toString();
    }
    else {
      return '';
    }

  }
  childData(item: any) {

    this.getScirptChildData(item.id, item.channelId);

    this.getChildrenList(item.id)
  }
  nameFolder(id: string, parentFolderId?: string) {
    if (this.writeAccess == false) {
      this.toastr.error("You don't have the permission to perform this action")
      return
    } else {
      this.router.navigateByUrl("/home/script/edit-folder/" + id + "/" + parentFolderId)
    }

  }
  createFolder(parentId: any, id: any) {
    if (this.writeAccess == false) {
      this.toastr.error("You don't have the permission to perform this action")
      return
    } else {
      this.dialog.open(CreateScriptFolderComponent, {
        width: '400px',
        data: { parentId, id },
      });
      this.dialog._getAfterAllClosed().subscribe(result => {
        this.getChildrenList(this.row.id)
      })
    }
  }

  deletefolder(id: { folderName: any; }, folder: any) {
    if (this.writeAccess == false) {
      this.toastr.error("You don't have the permission to perform this action")
      return
    } else {
      this.delData = id;
      this.delModalBody = `Do you want to Delete <b>${id.folderName}</b>?`;
      this.showConfirmDel1 = true;
    }
  }
  RenameFolder(parentId: any, id: any, folderName: any, channelId: any) {
    if (this.writeAccess == false) {
      this.toastr.error("You don't have the permission to perform this action")
      return
    } else {
      this.dialog.open(RenameScriptFolderComponent, {
        width: '400px',
        data: { parentId, id, folderName, channelId },
      });
      this.dialog._getAfterAllClosed().subscribe(result => {
       
      })
    }
  }
  getChildrenList(id: any) {
    this.apiService.getchildListFolder(id).subscribe((s: any) => {
      //var iFind= findItemNested(this.folderList, item.id, "children")
    
      this.row = {
        ...this.row,
        children: s
      }
      this.childfolderData = s;
      
      console.log(this.folderServiceList)
    })
  }
  getScirptChildData(id: any, channelId: any) {
    this.selectedid = id
    let scriptList = null;

    this.apiService.getSprintListFolder(id).subscribe(res => {
      this.ScriptData = res
      
      scriptList = res;
      this.row = {
        ...this.row,
        scriptList: res
      }
      this.listScriptFolder = res;
      // return scriptList;

    })
    console.log(this.folderServiceList);
  }
  sprintList(id: any) {
    this.dialog.open(ScriptBusyComponent, {
      autoFocus: false,
      panelClass: 'mwa-dialog',
      height: '500px',
      width: '800px',
      data: id
    }).afterClosed().subscribe(res => {
    });
  }
}
