import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { requestsService } from 'src/app/services/requests/requests.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  listNotification=[];
  allRead=false;
  checked:boolean=false;
  disabled:boolean=false;
  listnoti=[]
  constructor(private apiService: requestsService, private router: Router,private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.listOfNotification();
  }
  listOfNotification(){
    this.apiService.latestNotifications().subscribe(res=>{
      this.listnoti=res.body.result
      this.listNotification=res.body.result
      console.log(this.listNotification)
      this.listNotification = this.listNotification.map(item => {
        this.allRead = this.listNotification.some(item => !item.isRead);
        console.log(this.allRead)
        if (item.notificationDetailsURL && !/^https?:\/\//i.test(item.notificationDetailsURL)) {
          item.notificationDetailsURL = 'http://' + item.notificationDetailsURL;
        }
        return item;
      });
  
    });
    
  }
  toggle(event){
    console.log(event.checked)
    if(event.checked==true){
      this.listNotification = this.listnoti.filter(item => item.isRead==false)
    }else{
      this.listNotification = this.listnoti
    }
  }
  readNotoification(notificationId){
    this.apiService.updateFlag(notificationId).subscribe(res=>{
      if(res==true){
        this.listOfNotification()
      }
    })
  }
  markRead(){
    this.apiService.markAllRead().subscribe(res=>{
      this.listOfNotification()
    })
  }
  navigate(url){
    window.open(url)
  }
}
