import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() total = 0;
  @Input() limit = 10;
  @Input() newTotal ;
  @Output() change = new EventEmitter<number>();
  @Output()changeLimit= new EventEmitter<number>();
  first= false;
  totalPages = 0;
  pages = [];

  activePage = 0;
  totalVisiblePages = 5;

  constructor() { }

  ngOnInit() {
    this.totalPages = Math.ceil(this.total / this.limit);
    this.gotoFirst(false);
    console.log(this.totalPages,this.activePage)
  }

  ngOnChanges(changes: SimpleChanges) { 
    if(changes.limit?.currentValue){
      // if(changes.limit.currentValue>this.total && this.first){
      //   this.limit=this.total-1

      // }else{
      //   this.limit=changes.limit.currentValue;
      // }
      // this.totalPages = Math.ceil(this.total / changes.limit.currentValue);
      // this.activePage = 0;
      // this.gotoFirst(false);
    }
    if (changes.total) {
      this.totalPages = Math.ceil(changes.total.currentValue / this.limit);
      this.gotoFirst(false);
      this.first= true
    }
  
    if(changes.newTotal?.currentValue){
      if(changes.newTotal?.currentValue!==this.total){
        if(this.limit>changes.newTotal.currentValue){
          //this.limit=changes.newTotal.currentValue-1;
          this.totalPages = 1;

        }else{
        
          this.totalPages = Math.ceil(changes.newTotal.currentValue / this.limit);
        }
        this.total=changes.newTotal.currentValue ;
        

      }else{
        
        this.totalPages = Math.ceil(this.total / this.limit);
      }
      
      this.activePage = 0;
      this.gotoFirst(false);
    }
    
  }
  changePagination(event){
    console.log(event);
    switch(event){
      case '':
        this.limit=10;
        break;
      case 'all':
        
        this.limit=this.total;
        break;
     
      case '25':
        this.limit=25;
        break;
     case '50':
      this.limit=50;
      break; 
      case '100':
        this.limit=100     ;
        break;          
    }
    this.changeLimit.emit(this.limit);
    if(this.limit>this.total){
      this.limit=this.total-1
      this.totalPages =1;

    }else{
      this.totalPages = Math.ceil(this.total / this.limit);

    }
    this.activePage = 0;
    this.gotoFirst(false);
  }
  goto(page) {
    console.log(page)
    const position = this.pages.indexOf(page);
    if (this.totalVisiblePages - position < this.totalVisiblePages / 2) {
      for (let i = 1; i < Math.ceil(this.totalVisiblePages / position) && this.pages[this.pages.length - 1] < this.totalPages - 1; i++) {
        this.pages.push(this.pages[this.pages.length - 1] + 1);
        this.pages.splice(0, 1);
      }
    } else if (this.totalVisiblePages - position > this.totalVisiblePages / 2) {
      for (let i = 1; i < Math.ceil(this.totalVisiblePages / 2) - position && this.pages[0] > 0; i++) {
        this.pages.splice(0, -1, this.pages[0] - 1);
        this.pages.splice(this.pages.length - 1, 1);
      }
    }

    this.activePage = page;
    this.change.emit(this.activePage);
  }
  gotoFirst(doEmit = true) {
    this.pages = [];
    for (let i = 0; i < this.totalVisiblePages && i < this.totalVisiblePages && this.pages.length < this.totalPages; i++) {
      this.pages.push(i);
    }

    this.activePage = 0;
    if (doEmit) {
      this.change.emit(this.activePage);
    }
  }

  gotoLast() {
    this.pages = [];
    for (let i = this.totalPages - 1; this.pages.length < this.totalVisiblePages && i > -1; i--) {
      this.pages.splice(0, -1, i);
    }

    this.activePage = this.totalPages - 1;
    this.change.emit(this.activePage);
  }

  gotoBack() {
    const nxtpage = this.activePage - 1;
    if (nxtpage < 0) {
      return;
    }
    this.goto(nxtpage);
  }

  gotoNext() {
    const nxtpage = this.activePage + 1;
    if (nxtpage > this.totalPages - 1) {
      return;
    }
    this.goto(nxtpage);
  }
}
