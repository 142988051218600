<div class="Note">Notification</div>
    <div body class="showList">
        <div body class="data-table">
            <div class="row">
                <div class="readall" style="padding-left: 33px;color: #9e5ce1;" *ngIf="allRead" (click)="markRead()">Mark all as read</div>
                <div class="header cell">
                  <mat-slide-toggle 
          class="example-margin" (change)="toggle($event)"
          [checked]="checked"
          [disabled]="disabled">
          Only show unread  
      </mat-slide-toggle>
                </div>
                <div class="row list" *ngFor="let itemNotificatin of listNotification; let i = index">
                    <div 
                      class="cell" 
                      [ngClass]="{'bold-text': !itemNotificatin.isRead}" 
                      [ngStyle]="{'text-decoration': itemNotificatin.notificationDetailsURL ? 'underline' : 'none'}" 
                      style="word-wrap: break-word; word-break: break-all;" (click)="readNotoification(itemNotificatin.id)">
                      <ng-container *ngIf="itemNotificatin.notificationDetailsURL; else noLink">
                        <a [href]="itemNotificatin.notificationDetailsURL" target="_blank" style="text-decoration: none; color: inherit;">
                          {{ itemNotificatin.notificationMessage }}
                        </a>
                        <img class="def focus dis" (click)="navigate(itemNotificatin.notificationDetailsURL)" style="padding-left: 10px; overflow: hidden;width: 30px;" src="assets/icons/icons8-external-link.svg">
                      </ng-container>
                      <ng-template #noLink>
                        {{ itemNotificatin.notificationMessage }}
                      </ng-template>
                    </div>
                  </div>
            </div>
        </div>
    </div>

