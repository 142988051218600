import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { HeadingComponent } from './heading/heading.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormInputComponent } from './forms/form-input/form-input.component';
import { ButtonComponent } from './button/button.component';
import { PageContainerComponent } from './page-container/page-container.component';
import { CardComponent } from './card/card.component';
import { DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarComponent } from './calendar/calendar.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { AlertComponent } from './alert/alert.component';
import { FormCheckboxComponent } from './forms/form-checkbox/form-checkbox.component';
import { FormSelectComponent } from './forms/form-select/form-select.component';
import { ModalComponent } from './modal/modal.component';
import { DomChangesDirective } from './directives/dom-changes.directive';
import { DropDownFolderComponent } from './drop-down-folder/drop-down-folder.component';
import { FolderItemComponent } from './folder-item/folder-item.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [HeaderComponent, HeadingComponent,
     FormInputComponent, ButtonComponent, PageContainerComponent,
      CardComponent, CalendarComponent, PaginatorComponent, 
      DropdownComponent, TimePickerComponent, 
      DatePickerComponent, AlertComponent, FormCheckboxComponent, 
      FormSelectComponent, ModalComponent, DomChangesDirective,
       DropDownFolderComponent, FolderItemComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatExpansionModule,
    DragDropModule,
    MatIconModule,
    MatSlideToggleModule,
  ],
  exports: [
    HeaderComponent,
    HeadingComponent,
    FormInputComponent,
    ButtonComponent,
    PageContainerComponent,
    CardComponent,
   DropDownFolderComponent,
    PaginatorComponent,
    DropdownComponent,
    TimePickerComponent,
    DatePickerComponent,
    AlertComponent,
    FormCheckboxComponent,
    FormSelectComponent,
    ModalComponent, 
    DomChangesDirective,
    FolderItemComponent,
    MatSlideToggleModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class CommonComponentsModule { }
