<div class="common-modal-wrapper" *ngIf="open">
    <div class="overlay" (click)="onClose()"></div>
    <div class="modal-body  animate fade-in" [ngClass]="type">
        <div class="body-bg">
            <img *ngIf="placeholder" class="placeholder" [src]="'/assets/illustrations/'+placeholder">
            <img src="/assets/icons/close-light.svg" class="close" (click)="onClose()">
        </div>
        <div class="body-container">
            <div class="text" [innerHTML]="body"></div>
            <div class="btn-set">
                <div class="btn">
                    <app-button [color]="type" [size]="'sm'" (click)="onClose(true)">{{primaryBtn}}</app-button>
                </div>
                <div class="btn">
                    <app-button [color]="type" [outline]="true" [size]="'sm'" (click)="onClose(false)">{{secondaryBtn}}
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</div>