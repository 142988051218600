<div class="common-form-input" #panelDOM>
    <div class="label" *ngIf="label">{{ label }} <span class="required-text" *ngIf="required"><span style="color: #ff0000;
        font-size: 19px;">*</span></span></div>
    <div class="phone-wrap" *ngIf="type == 'phone'">
        <select (change)="emitValue1($event.target.value)">
            <option *ngFor="let item of phoneCodeList" [value]="item?.dial_code">{{ item?.name }}</option>
        </select>
        <select [(ngModel)]="phoneCode" (change)="emitValue()">
            <option *ngFor="let item of phoneCodeList">{{ item?.dial_code }}</option>
        </select>
        <input type="text" [(ngModel)]="phoneInput" (keyup)="emitValue()" maxlength="10">
    </div>
    <div (click)="togglePicker()">
        <ng-content select="input"></ng-content>
    </div>
    <div class="suffix" (click)="togglePicker()">
        <ng-content select="[suffix]"></ng-content>
        <img src="/assets/icons/calendar.svg" *ngIf="type == 'date'">
    </div>
    <div class="date-wrap" *ngIf="showDatePicker">
        <app-date-picker (change)="setDate($event)" [disableInit]="true" [disableFutureDates]="disableFutureDates">
        </app-date-picker>
    </div>
    <div class="field-error" *ngIf="error && error.errors && error.touched">{{ getError }}</div>
</div>