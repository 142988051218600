import {Injectable} from '@angular/core';


@Injectable({ providedIn: 'root' })
export class Config {
    
    clientID:string= '18da0657-3a58-44f8-8ab0-e45da6b773b5';
                      ///3fa0df84-2e00-4d8e-a8d2-2eadd12ba562
    clientSecret:string= 'MlIiBQMZgoGwV-oB_SjHgMSnLo4lqGJjxYZHIfTVhqc';

     wizardUriBase:string= 'https://genesys-pre-prod.d380tp1808yrf1.amplifyapp.com';
    //  pcEnvironment:string= 'https://genesys-pre-prod.d380tp1808yrf1.amplifyapp.com/';
    //  pcLangTag:string= 'en-west_2';
     //https://genesys.d14rsryohcu9ot.amplifyapp.com/
    //wizardUriBase: 'https://mypurecloud.github.io/QuickTest/',

    // The actual URL of the landing page of your web app.
    // 'premiumAppURL': 'http://localhost:8080/premium-app-sample/index.html',
    //premiumAppURL: 'git https://mypurecloud.github.io/purecloud-premium-app/premium-app-sample/index.html',
    premiumAppURL:string='https://genesys-pre-prod.d380tp1808yrf1.amplifyapp.com?langTag={{pcLangTag}}&environment={{pcEnvironment}}';
    //premiumAppURL:string='https://genesys.d14rsryohcu9ot.amplifyapp.com/#?langTag={{pcLangTag}}&environment={{pcEnvironment}}';
    //https://mypurecloud.github.io/QuickTest/wizard/index.html?langTag={{pcLangTag}}&environment={{pcEnvironment}},
    // Genesys Cloud assigned name for the premium app
    // This should match the integration type name of the Premium App
    // NOTE: During initial development please use ‘premium-app-example’.
    //            Once your premium app is approved an integration type will be created
    //            by the Genesys Cloud product team and you can update the name at that time.
   // appName:string= 'premium-app-example';
    appName:string= 'premium-app-example';
    // The minimum permission required for a user to access the Premium App.
    // NOTE: During initial development please use the default permission 
    //      'integration:examplePremiumApp:view'. Once your premium app is approved,
    //      the unique integration domain will be generated and this must be updated.
    viewPermission:string= 'integration:examplePremiumApp:view';
    
    // https://genesys.d14rsryohcu9ot.amplifyapp.com/
    // https://genesys.d14rsryohcu9ot.amplifyapp.com/
    // Default Values for fail-safe/testing. Shouldn't have to be changed since the app
    // must be able to determine the environment from the query parameter 
    // of the integration's URL
    // defaultPcEnvironment:string= 'euw2.pure.cloud/oauth';
    // defaultLanguage:string= 'en-west_2';
    pcEnvironmentQueryParam: 'pcEnvironment'

    // The names of the query parameters to check in 
    // determining language and environment
    // Ex: www.electric-sheep-app.com?langTag=en-us&environment=mypurecloud.com
    languageQueryParam:string= 'langTag';
    genesysCloudEnvironmentQueryParam:string= 'environment'

    // Permissions required for running the Wizard App
    setupPermissionsRequired:any= ['admin'];

    // Enable the optional 'Step 2' in the porivisoning process
    // If false, it will not show the page or the step in the wizard
    enableCustomSetup:boolean= true;

    // To be added to names of Genesys Cloud objects created by the wizard
    prefix:string= 'Partner';

    // These are the Genesys Cloud items that will be added and provisioned by the wizard
    provisioningInfo: any= {
        'role': [
            {
                'name': 'SuperAdmin',
                'description': 'Generated role for access to the app.',
                'permissionPolicies': [
                    {
                        'domain': 'integration',
                        'entityName': 'Partner',
                        'actionSet': ['*'],
                        'allowConditions': false
                    }
                ]
            }
        ],
        'group': [
            {
                'name': 'Supervisor',
                'description': 'Supervisors have the ability to watch a queue for ACD conversations.',
            }
        ],
        'appinstance': [
            {
                'name': 'QuickTest',
                //https://mypurecloud.github.io/QuickTest/wizard/index.html?langTag={{pcLangTag}}&environment={{pcEnvironment}}
                'url': 'https://genesys-pre-prod.d380tp1808yrf1.amplifyapp.com?&environment={{pcEnvironment}}',
                'type': 'standalone',
                'groups': ['Supervisors'],
                'id':"0"       
            }
        ],
        'oauthclient': [
            {
                'name': 'QuickTest',
                'description': 'Generated Client that\'s passed to the App Backend',
                'roles': ['Role'],
                //'authorizedGrantType': 'CLIENT_CREDENTIALS',
                'authorizedGrantType': 'TOKEN',
                'registeredRedirectUri':'https://genesys-pre-prod.d380tp1808yrf1.amplifyapp.com',
                // 'scope':'alerting:readonly' https://www.genesys.quicktest.ai/,
                /**
                 * This function is for other processing that needs
                 * to be done after creating an object.
                 * 'finally' is available for all the other
                 * resources configured in this config file.
                 * NOTE: Finally functions must return a Promise.
                 * For Client Credentials, normally it means
                 * passing the details to the backend.
                 * @param {Object} installedData the Genesys Cloud resource created
                 * @returns {Promise}    
                 */
                'finally': function(installedData){
                    return new Promise((resolve, reject) => {
                        console.log('Fake Sending Credentials...');
                        //setTimeout(() => resolve(), 2000);
                    });
                }
            }
        ]
    }
}

