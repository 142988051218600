import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { requestsService } from '../requests/requests.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private requestService: requestsService) { }
  isPlatFormAdmin(){
    return this.requestService.query('/isPlatFormAdmin')
  }
  getAdminOrgList(){
    return this.requestService.query(`/qtAdminReport/getOrgList`)
  }
  AdminSprintReport(params): Observable<any> {
    return this.requestService.post('/qtAdminReport/billing', params);
}
AdmingetOrg(orgId){
  return this.requestService.query(`/qtAdminReport/getOrgInfo?orgId=${orgId}`);
}
AdminUpdateOrg(params): Observable<any> {
  return this.requestService.post('/qtAdminReport/updateOrgInfo', params);
}

  adminInvoiceReport(params): Observable<any[]> {
    return this.requestService.post('/qtAdminReport/getInvoiceDetails', params);
}
getAllUsersRoleListByOrg(orgId){
  return this.requestService.query(`/qtAdminReport/getAllUsersRoleListByOrg?orgId=${orgId}`)
}
enableUserAdmin(userId){
  return this.requestService.query(`/qtAdminReport/enableUser?userId=`+ userId)

}
disableUserAdmin(userId){
  return this.requestService.query(`/qtAdminReport/disableUser?userId=`+ userId)
}
saveRoleListForUserAdmin(params){
  return this.requestService.post(`/qtAdminReport/saveRoleListForUser`,params)
}
listAllNotification(){
  return this.requestService.query(`/userSubscribeNotification/listAllNotification`)
}
saveNotif(params){
  return this.requestService.post(`/mainNotification/saveNotif`,params)
}
listAllNotificationForQTAdmin(){
  return this.requestService.query(`/userSubscribeNotification/listAllNotificationForQTAdmin`)
}
}
